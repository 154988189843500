import { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import config from './config';
import moment from 'moment';



class ResultTable extends Component  {
  removeNoTime = (time) => {
    return time.replace(" 0:00am", "");
  };

  render () {
    return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Lab Name (LOINC code)</TableCell>
            <TableCell align="center">Date</TableCell>
            <TableCell align="right">Value</TableCell>
            <TableCell align="center">Interpretation</TableCell>
            <TableCell align="center">Performer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.results.map((result) => (
            <TableRow
              key={result.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {result.text} ({result.LOINC})
              </TableCell>
              <TableCell align="center">{result.effectiveDateTime === "Time Missing" ? result.effectiveDateTime : this.removeNoTime(moment(result.effectiveDateTime).format(config.dateFormat))}</TableCell>
              <TableCell align="right"><span style={{fontWeight: 800}}>{result.valueString ? result.valueString : result.value}&nbsp;{result.unit}</span></TableCell>
              <TableCell align="center">{result.interpretation}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
  }
}

export default ResultTable; 