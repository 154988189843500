import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import ShowNote from "./C_ShowNote";
import NoteSearch from "./C_NoteSearch";
import { CCollapse, CCard, CCardBody } from "@coreui/react";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import config from "./config";
import moment from "moment";

const Head = styled(Paper)(({ theme }) => ({
  backgroundColor: "#008776",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  fontWeight: "bold",
}));

const bigModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "90%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class Notes extends Component {
  state = {
    note0: false,
    note1: false,
    note2: false,
    modalNote: false, 
    modalSearch: false,
  };

  setVisible = (i) => {
    let node = "note" + i;
    // console.log(`note click ${node}`);
    let newState = {
      note0: false,
      note1: false,
      note2: false,
    };
    if (this.state[node]) newState[node] = false;
    else newState[node] = true;
    this.setState(newState);
  };

  iconState = function (visible, i) {
    if (!visible) return <ArrowDropDownIcon id={i}></ArrowDropDownIcon>;
    else return <ArrowDropUpIcon id={i}></ArrowDropUpIcon>;
  };

  noteModal = (e) => {
    let note = this.props.notes[e.target.id];
    this.setState({
      modalNote: true,
      currentNote: note,
    });
  };

  handleClose = () => {
    this.setState({
      modalNote: false,
      modalSearch: false
    });
  };

  showSearch = () => {
    this.setState({
      modalSearch: true
    })
  }

  render() {
    return (
      <Grid item xs={12} md={4}>
        <Head>
          Notes
          <input
            className="searchPanel"
            placeholder="Click to activate SEARCH"
          onClick={this.showSearch}></input>
        </Head>
        {this.props.notes &&
          this.props.notes.map((note, i) => (
            <div key={"note" + i}>
              <Button
                id={i}
                onClick={() => {
                  this.setVisible(i);
                }}
              >
                {this.iconState(this.state["note" + i], i)}
                Note {i + 1}
              </Button>
              <span style={{ fontWeight: 500 }}>{note.type ? note.type.text : ""}</span>
              {" "}
              {moment(note.date).format(config.dateFormat)}
              <CCollapse id={"note" + i} visible={this.state["note" + i]}>
                <CCard className="mt-3">
                  <CCardBody>
                    {atob(note.content[0].attachment.data)
                      .slice(0, 600)
                      .replace(/(\r\n|\n|\r)/gm, "  ")}
                    <button id={i} className="link" onClick={this.noteModal}>
                      ...Full note
                    </button>
                  </CCardBody>
                </CCard>
              </CCollapse>
            </div>
          ))}
        <div>
          <span
            className="centerSpan"
            style={{ display: this.props.notes.length ? "none" : "table" }}
          >
            <br />
            <b>
              <i>No notes found for patient</i>
            </b>
          </span>
        </div>
        <Modal
          id="modalNote"
          open={this.state.modalNote}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={bigModalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Note Detail
              <button className="closeButton" onClick={this.handleClose}>
                <Close />
              </button>
            </Typography>
            <ShowNote note={this.state.currentNote} />
          </Box>
        </Modal>
        <Modal
          id="modalSearch"
          open={this.state.modalSearch}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={bigModalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Note Search
              <button className="closeButton" onClick={this.handleClose}>
                <Close />
              </button>
            </Typography>
            <NoteSearch notes={this.props.notes}/>
          </Box>
        </Modal>
      </Grid>
    );
  }
}

export default Notes;
