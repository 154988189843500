import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";

const Head = styled(Paper)(({ theme }) => ({
  backgroundColor: "#008776",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  fontWeight: "bold",
}));

class Meds extends Component {
  render() {
    this.medications = [];
    if (this.props.medications) {
      for (let i = 0; i < this.props.medications.length; i++) {
        let med = {}
        if (this.props.medications[i].medicationCodeableConcept && this.props.medications[i].medicationCodeableConcept.text) med.text = this.props.medications[i].medicationCodeableConcept.text;
        else med.text = 'No Description provided'
        if (this.props.medications[i].dosageInstruction && this.props.medications[i].dosageInstruction[0] && this.props.medications[i].dosageInstruction[0].text) med.sig = this.props.medications[i].dosageInstruction[0].text
        else med.sig = '';
        this.medications.push(med);
      }
    }

    return (
      <Grid item xs={12} md={4}>
        <Head>Medications</Head>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {this.medications &&
            this.medications.map((med, i) => {
              return (
                <li key={"med" + i}>
                  <span style={{ fontWeight: 500  }}>{med.text}</span>{" "}<br/>{med.sig}
                </li>
              );
            })}
        </ul>
      </Grid>
    );
  }
}

export default Meds;
