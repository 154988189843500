import React, { Component } from "react";
import { Select, InputLabel, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import Close from "@mui/icons-material/Close";
import CircularProgress from "@mui/joy/CircularProgress";
import config from "./config";
import { IHTObservation } from "./helpers/IHTObservation";
  import axios from "axios";
import moment from "moment";

const Head = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1976D2",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  fontWeight: "bold",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: "1px", 
  textAlign: "left",
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SubHead2 = styled(Paper)(({ theme }) => ({
  backgroundColor: "#1975d2",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: 'white',
  fontWeight: "bold",
}));

const calcAge = function (birthDate) {
  if (!birthDate) return "";
  else return moment().diff(birthDate, "years");
};

class Snapshot extends Component {
  state = {
    age: "",
    illnessSeverity: "",
    modalAction: false,
    modalOther: false,
    modalSave: false,
    otherComplaint: "",
    primaryComplaint: "",
    reason: "",
    actions: {
      cardiology: faSquare,
      oncology: faSquare,
      mri: faSquare,
      ct: faSquare,
      xray: faSquare,
      ultrasound: faSquare,
      endoscopy: faSquare,
      eeg: faSquare,
      bypass: faSquare,
      other1: faSquare,
      other2: faSquare,
      other3: faSquare,
    },
    actionNames: {
      cardiology: "Cardiology Consult",
      oncology: "Oncology Consult",
      mri: "MRI Order",
      ct: "CT Order",
      xray: "X-Ray Order",
      ultrasound: "Ultrasound Order",
      endoscopy: "Endoscopy",
      eeg: "EEG",
      bypass: "Bypass",
    },
    otherActions: {
      other1: "",
      other2: "",
      other3: "",
    },
    othersSelected: false,
    actionDisplay: "",
    temp: [],
    problems: [],
    unit: "Cel",
  };

  illnessChange = (e) => {
    this.setState({ illnessSeverity: e.target.value });
  };

  reasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  primaryComplaint = (e) => {
    if (e.target.value === "Set new primary complaint") {
      this.handleOtherOpen();
    } else {
      this.setState({ primaryComplaint: e.target.value });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.Patient.birthDate !== prevProps.Patient.birthDate) {
      if (this.props.custom && this.props.custom.length) {
        let problems = JSON.parse(JSON.stringify(this.props.problems));
        let parts = this.props.custom[0].valueString.split("|");
        let newIllnessSeverity = parts[0];
        let newReason = parts[1];
        let primaryComplaint = parts[2];
        if (primaryComplaint) {
          let newProblem = {
            id: "user-defined",
            code: {
              text: primaryComplaint,
            },
          };
          problems.push(newProblem);
        }
        let newAction = parts[3];
        let actionDisplays = newAction.split(",");
        let actions = {
          cardiology: faSquare,
          oncology: faSquare,
          mri: faSquare,
          ct: faSquare,
          xray: faSquare,
          ultrasound: faSquare,
          endoscopy: faSquare,
          eeg: faSquare,
          bypass: faSquare,
          other1: faSquare,
          other2: faSquare,
          other3: faSquare,
        };
        // console.log(actionDisplays);
        for (let key in this.state.actionNames) {
          if (this.state.actionNames.hasOwnProperty(key)) {
            for (let i = 0; i < actionDisplays.length; i++) {
              if (this.state.actionNames[key] === actionDisplays[i].trim()) {
                actions[key] = faSquareCheck;
                break;
              }
            }
          }
        }
        this.setState({
          actions: actions,
          age: calcAge(this.props.Patient.birthDate),
          illnessSeverity: newIllnessSeverity,
          primaryComplaint: primaryComplaint,
          reason: newReason,
          temp: this.props.vitals.temp,
          problems: problems,
          actionDisplay: newAction,
        });
      } else {
        this.setState({
          age: calcAge(this.props.Patient.birthDate),
          temp: this.props.vitals.temp,
          problems: JSON.parse(JSON.stringify(this.props.problems)),
        });
      }
    }
  }

  flipAction = (e) => {
    let target = e.target.id;
    if (!target) {
      target = e.target.parentNode.id;
    }
    if (this.state.actions[target].iconName === "square-check") {
      let state = {
        actions: this.state.actions,
      };
      state.actions[target] = faSquare;
      this.setState(state);
    } else {
      let state = {
        actions: this.state.actions,
      };
      state.actions[target] = faSquareCheck;
      this.setState(state);
    }
  };

  handleActionOpen = () => {
    let actions = this.state.actions;
    let priorActions = false;
    if (this.state.otherActions.other1) {
      actions.other1 = faSquareCheck;
      priorActions = true;
    } else actions.other1 = faSquare;
    if (this.state.otherActions.other2) {
      actions.other2 = faSquareCheck;
      priorActions = true;
    } else actions.other2 = faSquare;
    if (this.state.otherActions.other3) {
      actions.other3 = faSquareCheck;
      priorActions = true;
    } else actions.other3 = faSquare;
    this.setState({
      modalAction: true,
      actions: actions,
      priorActions: priorActions,
    });
  };

  handleActionClose = (e) => {
    let newDisplay = "";
    let count = 0;
    let newOtherState = {
      other1: "",
      other2: "",
      other3: "",
    };
    for (let key in this.state.actions) {
      if (this.state.actions.hasOwnProperty(key)) {
        if (
          this.state.actions[key] &&
          this.state.actions[key].iconName &&
          this.state.actions[key].iconName === "square-check"
        ) {
          if (newDisplay !== "" && !key.includes("other")) newDisplay += ", ";
          if (this.state.actionNames[key]) {
            newDisplay += this.state.actionNames[key];
          }
        }
      }
    }
    if (this.state.otherSelected) {
      if (
        this.state.actions.other1.iconName === "square" &&
        this.state.actions.other2.iconName === "square" &&
        this.state.actions.other3.iconName === "square"
      ) {
        count = 0;
      } else {
        if (
          this.state.actions.other1.iconName !== "square" &&
          this.state.otherActions.other1
        ) {
          if (newDisplay !== "") newDisplay += ", ";
          newOtherState.other1 = this.state.otherActions.other1;
          newDisplay += this.state.otherActions.other1;
          count++;
        }
        if (
          this.state.actions.other2.iconName !== "square" &&
          this.state.otherActions.other2
        ) {
          if (newDisplay !== "") newDisplay += ", ";
          newOtherState.other2 = this.state.otherActions.other2;
          newDisplay += this.state.otherActions.other2;
          count++;
        }
        if (
          this.state.actions.other3.iconName !== "square" &&
          this.state.otherActions.other3
        ) {
          if (newDisplay !== "") newDisplay += ", ";
          newOtherState.other3 = this.state.otherActions.other3;
          newDisplay += this.state.otherActions.other3;
          count++;
        }
      }
    } else {
      if (this.state.otherActions.other1) {
        if (newDisplay !== "") newDisplay += ", ";
        newOtherState.other1 = this.state.otherActions.other1;
        newDisplay += this.state.otherActions.other1;
        count++;
      }
      if (this.state.otherActions.other2) {
        if (newDisplay !== "") newDisplay += ", ";
        newOtherState.other2 = this.state.otherActions.other2;
        newDisplay += this.state.otherActions.other2;
        count++;
      }
      if (this.state.otherActions.other3) {
        if (newDisplay !== "") newDisplay += ", ";
        newOtherState.other3 = this.state.otherActions.other3;
        newDisplay += this.state.otherActions.other3;
        count++;
      }
    }
    this.setState({
      modalAction: false,
      actionDisplay: newDisplay,
      otherActions: newOtherState,
      otherSelected: count ? true : false,
    });
  };

  handleOtherAction = (e) => {
    let actions = {};
    if (e.target.value) {
      actions[e.target.id] = e.target.value;
    } else {
      actions[e.target.id] = false;
    }
    if (e.target.id !== "other1")
      actions["other1"] = this.state.otherActions.other1;
    if (e.target.id !== "other2")
      actions["other2"] = this.state.otherActions.other2;
    if (e.target.id !== "other3")
      actions["other3"] = this.state.otherActions.other3;
    this.setState({
      otherActions: actions,
    });
  };

  handleOtherOpen = (e) => {
    this.setState({
      modalOther: true,
      primaryComplaint: "",
      problems: JSON.parse(JSON.stringify(this.props.problems)),
      newProblem: null,
    });
    console.log(this.state.problems);
  };

  handleOtherClose = (e) => {
    let newProblems = this.state.problems;
    if (this.state.newProblem) {
      newProblems.push(this.state.newProblem);
      this.setState({
        problems: newProblems,
        primaryComplaint: this.state.newProblem.code.text
          ? this.state.newProblem.code.text
          : "",
        modalOther: false,
      });
    } else {
      this.setState({
        modalOther: false,
      });
    }
  };

  updateComplaint = (e) => {
    let newProblem = {
      id: "user-defined",
      code: {
        text: e.target.value,
      },
    };
    this.setState({
      newProblem: newProblem,
    });
  };

  formatLastVital = (array, digits, nodate) => {
    if (
      array &&
      array.length &&
      array[array.length - 1].value &&
      array[array.length - 1].value.toFixed
    ) {
      let value = array[array.length - 1].value
        ? array[array.length - 1].value.toFixed(digits)
        : "none";
      let unit = array[array.length - 1].unit
        ? array[array.length - 1].unit
        : "";
      let date = "";
      if (array[array.length - 1].effectiveDateTime) {
        date = moment(array[array.length - 1].effectiveDateTime).format(
          config.dateFormat
        );
      }
      if (nodate === "only") {
        return date;
      } else if (nodate) {
        return value + " " + unit;
      } else {
        return value + " " + unit + " " + date;
      }
    } else {
      return "none";
    }
  };

  convertTemp = (array, convertToFarenheit) => {
    for (let i = 0; i < array.length; i++) {
      if (convertToFarenheit) {
        array[i].value = (array[i].value * 9) / 5 + 32;
        array[i].unit = "F";
      } else {
        array[i].value = ((array[i].value - 32) * 5) / 9;
        array[i].unit = "Cel";
      }
    }
    return array;
  };

  buttonConvert = (e) => {
    let convertToFarenheit = false;
    if (e.target.value === "Cel → F") convertToFarenheit = true;
    let newTemps = this.convertTemp(this.state.temp, convertToFarenheit);
    this.setState({
      temp: newTemps,
      unit: convertToFarenheit ? "F" : "Cel",
    });
  };

  formatLastBP = (type, array, digits, nodate) => {
    if (array && array.length) {
      let component = array[array.length - 1].component;
      if (component) {
        for (let i = 0; i < component.length; i++) {
          if (component[i].code.coding[0].code === "8480-6" && type === "sys") {
            array[array.length - 1].value = component[i].valueQuantity.value;
            array[array.length - 1].unit = component[i].valueQuantity.unit;
            return this.formatLastVital(array, digits, nodate);
          } else if (
            component[i].code.coding[0].code === "8462-4" &&
            type === "dia"
          ) {
            array[array.length - 1].value = component[i].valueQuantity.value;
            array[array.length - 1].unit = component[i].valueQuantity.unit;
            return this.formatLastVital(array, digits, nodate);
          }
        }
        return "none";
      }
    } else {
      return "none";
    }
  };

  sysFormatBP = (bp) => {
    let newData = [];
    for (let i = 0; i < bp.length; i++) {
      let newResult = {
        LOINC: "",
        text: "",
        id: bp[i].id + "systolic",
        effectiveDateTime: bp[i].effectiveDateTime,
        value: "",
        unit: "mmHg",
      };
      let component = bp[i].component;
      newResult.LOINC = "8480-6";
      newResult.text = "Systolic Blood Pressure";
      for (let j = 0; j < component.length; j++) {
        if (component[j].code.coding[0].code === "8480-6") {
          newResult.value = component[j].valueQuantity.value;
          break;
        }
      }
      newData.push(newResult);
    }
    return newData;
  };

  diaFormatBP = (bp) => {
    let newData = [];
    for (let i = 0; i < bp.length; i++) {
      let newResult = {
        LOINC: "",
        text: "",
        id: bp[i].id + "diastolic",
        effectiveDateTime: bp[i].effectiveDateTime,
        value: "",
        unit: "mmHg",
      };
      let component = bp[i].component;
      newResult.LOINC = "8462-4";
      newResult.text = "Diatolic Blood Pressure";
      for (let j = 0; j < component.length; j++) {
        if (component[j].code.coding[0].code === "8462-4") {
          newResult.value = component[j].valueQuantity.value;
          break;
        }
      }
      newData.push(newResult);
    }
    return newData;
  };

  saveData = () => {
    this.setState({
      modalSave: true,
    });
    let newCustomObservation = IHTObservation(this.state, this.props.Patient);
    console.log(newCustomObservation);
    if (this.props.custom && this.props.custom.length) {
      axios({
        method: "delete",
        url: `${config.server}/Observation/${this.props.custom[0].id}`,
      }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          axios({
            method: "post",
            url: `${config.server}/Observation/`,
            data: newCustomObservation,
            headers: {},
          }).then((res) => {
            console.log(res);
            if (res.status === 201) {
              alert("data saved");
              this.setState({
                modalSave: false,
              });
            } else {
              alert("data saving failed. Please refresh browser");
            }
          });
        } else {
          alert("data saving failed. Please refresh browser");
        }
      });
    } else {
      axios({
        method: "post",
        url: `${config.server}/Observation/`,
        data: newCustomObservation,
        headers: {},
      }).then((res) => {
        console.log(res);
        if (res.status === 201) {
          alert("data saved");
          this.setState({
            modalSave: false,
          });
        } else {
          alert("data saving failed. Please refresh browser");
        }
      });
    }
  };

  handleSaveClose = () => {};

  render() {
    return (
      <Grid item xs={12} md={4}>
        <Head>Editable Summary and Plan</Head>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <span>
              <Item>
                <b className="black">Reason: </b> {this.props.Patient.gender} {this.state.age}{" "}
                years old in
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.illnessSeverity}
                  label="Reason"
                  onChange={this.illnessChange}
                  style={{color: '#1975d2'}}
                >
                  <MenuItem key="stable" value={"Stable"}>
                    Stable
                  </MenuItem>
                  <MenuItem key="watcher" value={"Watcher"}>
                    Watcher
                  </MenuItem>
                  <MenuItem key="unstable" value={"Unstable"}>
                    Unstable
                  </MenuItem>
                </Select>
                condition to transfer due to
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.reason}
                  label="Transfer Reason"
                  onChange={this.reasonChange}
                  style={{color: '#1975d2'}}
                >
                  <MenuItem key="Proc" value={"Procedural Specialty Care"}>
                    Procedural Specialty Care
                  </MenuItem>
                  <MenuItem key="Spec" value={"Non-Procedural Specialty Care"}>
                    Non-Procedural Specialty Care
                  </MenuItem>
                  <MenuItem key="Cap" value={"Hospital Capacity"}>
                    Hospital Capacity
                  </MenuItem>
                  <MenuItem key="Pref" value={"Patient Preference"}>
                    Patient Preference
                  </MenuItem>
                  <MenuItem key="Cont" value={"Continuity of Care"}>
                    Continuity of Care
                  </MenuItem>
                  <MenuItem key="Other" value={"Other (see note)"}>
                    Other (see note)
                  </MenuItem>
                </Select>
                for
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.primaryComplaint}
                  label="Primary Complaint"
                  onChange={this.primaryComplaint}
                  style={{color: '#1975d2'}}
                >
                  {this.state.problems &&
                    this.state.problems.map((problem) => (
                      <MenuItem
                        key={problem.id || "nokey"}
                        value={
                          problem.code && problem.code.text
                            ? problem.code.text
                            : "No text available"
                        }
                      >
                        {problem.code && problem.code.text
                          ? problem.code.text
                          : "No text available"}
                      </MenuItem>
                    ))}
                  <MenuItem value={"Set new primary complaint"}>
                    Set new primary complaint
                  </MenuItem>
                </Select>
              </Item>
              <Modal
                id="modalActions"
                open={this.state.modalOther}
                onClose={this.handleSaveClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    New Primary Complaint
                    <button
                      className="closeButton"
                      onClick={this.handleOtherClose}
                    >
                      <Close />
                    </button>
                  </Typography>
                  <br />
                  <InputLabel>
                    New:{" "}
                    <input
                      id="otherComplaint"
                      name="otherComplaint"
                      placeholder="Type here"
                      onChange={this.updateComplaint}
                    />
                  </InputLabel>
                </Box>
              </Modal>
            </span>
            <Item>
              <b className="black">Problems: </b>
              {this.state.problems.map((problem) => (
                <span key={problem.id || "nokey"}>
                  {problem.code && problem.code.text
                    ? problem.code.text
                    : "No text available"}
                  ,
                </span>
              ))}
              <span
                style={{
                  display: this.state.problems.length ? "none" : "inline",
                }}
              >
                None
              </span>
            </Item>
            <Item>
              <b className="black">Action List: </b>{" "}
              {this.state.actionDisplay ? this.state.actionDisplay : "None"}
              <Button onClick={this.handleActionOpen}>
                <EditIcon></EditIcon>
              </Button>{" "}
              <Modal
                id="modalActions"
                open={this.state.modalAction}
                onClose={this.handleActionClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Action List
                    <button
                      className="closeButton"
                      onClick={this.handleActionClose}
                    >
                      <Close />
                    </button>
                  </Typography>
                  <SubHead2>Need for Consulting Services</SubHead2>
                  {/* make this a checkbox */}
                  <Button
                    id="cardiology"
                    name="cardiology"
                    onClick={this.flipAction}
                  >
                    <span
                      id="cardiology"
                      className={
                        this.state.actions.cardiology.iconName ===
                        "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="cardiology"
                        icon={this.state.actions.cardiology}
                      />
                      &nbsp;{this.state.actionNames.cardiology}
                    </span>
                  </Button>
                  <br />
                  <Button
                    id="oncology"
                    name="oncology"
                    onClick={this.flipAction}
                  >
                    <span
                      id="oncology"
                      className={
                        this.state.actions.oncology.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="oncology"
                        icon={this.state.actions.oncology}
                      />
                      &nbsp;Oncology Consult
                    </span>
                  </Button>
                  <br />
                  <SubHead2>Need for Imaging</SubHead2>
                  <Button id="mri" name="mri" onClick={this.flipAction}>
                    <span
                      id="mri"
                      className={
                        this.state.actions.mri.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon id="mri" icon={this.state.actions.mri} />
                      &nbsp;MRI Order
                    </span>
                  </Button>
                  <br />
                  <Button id="ct" name="ct" onClick={this.flipAction}>
                    <span
                      id="ct"
                      className={
                        this.state.actions.ct.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon id="ct" icon={this.state.actions.ct} />
                      &nbsp;CT Order
                    </span>
                  </Button>
                  <br />
                  <Button id="xray" name="xray" onClick={this.flipAction}>
                    <span
                      id="xray"
                      className={
                        this.state.actions.xray.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="xray"
                        icon={this.state.actions.xray}
                      />
                      &nbsp;X-Ray Order
                    </span>
                  </Button>
                  <br />
                  <Button
                    id="ultrasound"
                    name="ultrasound"
                    onClick={this.flipAction}
                  >
                    <span
                      id="ultrasound"
                      className={
                        this.state.actions.ultrasound.iconName ===
                        "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="ultrasound"
                        icon={this.state.actions.ultrasound}
                      />
                      &nbsp;Ultrasound Order
                    </span>
                  </Button>
                  <br />
                  <SubHead2>Need for Procedures</SubHead2>
                  <Button
                    id="endoscopy"
                    name="endoscopy"
                    onClick={this.flipAction}
                  >
                    <span
                      id="endoscopy"
                      className={
                        this.state.actions.endoscopy.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="endoscopy"
                        icon={this.state.actions.endoscopy}
                      />
                      &nbsp;Endoscopy
                    </span>
                  </Button>
                  <br />
                  <Button id="eeg" name="eeg" onClick={this.flipAction}>
                    <span
                      id="eeg"
                      className={
                        this.state.actions.eeg.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon id="eeg" icon={this.state.actions.eeg} />
                      &nbsp;EEG
                    </span>
                  </Button>
                  <br />
                  <Button id="bypass" name="bypass" onClick={this.flipAction}>
                    <span
                      id="bypass"
                      className={
                        this.state.actions.bypass.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="bypass"
                        icon={this.state.actions.bypass}
                      />
                      &nbsp;Bypass
                    </span>
                  </Button>
                  <SubHead2>Other</SubHead2>
                  <span
                    style={{
                      display: !this.state.priorActions ? "inline" : "none",
                    }}
                  >
                    <InputLabel>
                      New:{" "}
                      <input
                        id="other1"
                        name="other1"
                        placeholder="New action item"
                        onChange={this.handleOtherAction}
                      />
                    </InputLabel>
                  </span>
                  <span
                    style={{
                      display:
                        this.state.otherActions.other1 &&
                        !this.state.priorActions
                          ? "inline"
                          : "none",
                    }}
                  >
                    <InputLabel>
                      New:{" "}
                      <input
                        id="other2"
                        name="other2"
                        placeholder="New action item"
                        onChange={this.handleOtherAction}
                      />
                    </InputLabel>
                  </span>
                  <span
                    style={{
                      display:
                        this.state.otherActions.other2 &&
                        !this.state.priorActions
                          ? "inline"
                          : "none",
                    }}
                  >
                    <InputLabel>
                      New:{" "}
                      <input
                        id="other3"
                        name="other3"
                        placeholder="New action item"
                        onChange={this.handleOtherAction}
                      />
                    </InputLabel>
                  </span>
                  <Button
                    id="other1"
                    name="other1"
                    onClick={this.flipAction}
                    style={{
                      display:
                        this.state.priorActions &&
                        this.state.otherActions.other1
                          ? "inline"
                          : "none",
                    }}
                  >
                    <span
                      id="other1"
                      className={
                        this.state.actions.other1.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="other1"
                        icon={this.state.actions.other1}
                      />
                      &nbsp;{this.state.otherActions.other1}
                    </span>
                  </Button>
                  <br />
                  <Button
                    id="other2"
                    name="other2"
                    onClick={this.flipAction}
                    style={{
                      display:
                        this.state.priorActions &&
                        this.state.otherActions.other2
                          ? "inline"
                          : "none",
                    }}
                  >
                    <span
                      id="other2"
                      className={
                        this.state.actions.other2.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="other2"
                        icon={this.state.actions.other2}
                      />
                      &nbsp;{this.state.otherActions.other2}
                    </span>
                  </Button>
                  <br />
                  <Button
                    id="other3"
                    name="other3"
                    onClick={this.flipAction}
                    style={{
                      display:
                        this.state.priorActions &&
                        this.state.otherActions.other3
                          ? "inline"
                          : "none",
                    }}
                  >
                    <span
                      id="other3"
                      className={
                        this.state.actions.other3.iconName === "square-check"
                          ? "actionOn"
                          : "actionOff"
                      }
                    >
                      <FontAwesomeIcon
                        id="other3"
                        icon={this.state.actions.other3}
                      />
                      &nbsp;{this.state.otherActions.other3}
                    </span>
                  </Button>
                </Box>
              </Modal>
            </Item>
            <Item>
              <Button variant="contained" onClick={this.saveData}>
                Save Reason and Actions
              </Button>
              <Modal
                id="modalSave"
                open={this.state.modalSave}
                onClose={this.handleSaveClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={modalStyle}>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    style={{ textAlign: "center" }}
                  >
                    Saving reason and action data...
                    <br />
                    <CircularProgress />
                    <br />
                    This window will automatically close when data saved. If it
                    does not close within 10 seconds, please refresh browser
                  </Typography>
                </Box>
              </Modal>
            </Item>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Snapshot;
