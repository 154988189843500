// const fs = require('fs');
import { scaleLinear } from "d3";
import React, { Component } from "react";
import moment from "moment";
import config from "../config";

export class Bmp extends Component {
  fontSize = 8;
  // If you change height/width, you may also need to adjust viewBox
  chartHeight = 20;
  chartWidth = 100;
  x = scaleLinear().domain([0, 1]).range([0, this.chartWidth]);
  y = scaleLinear().domain([0, 1]).range([this.chartHeight, 0]);

  xDist = 0.07;

  state = {
    na: {},
    k: {},
    cl: {},
    co2: {},
    bun: {},
    cr: {},
    glucose: {}
  };

  assignToState = () => {
    let empty = {
      title: '', 
      value: '',
      unit: '',
      time: '',
      interpretation: 'black'
    }
    let na, k, cl, co2, bun, cr, glucose;
    if (this.props.results.sodium && this.props.results.sodium.length) {
      na = {
        title: 'Sodium', 
        value: this.props.results.sodium[this.props.results.sodium.length -1].value,
        unit: this.props.results.sodium[this.props.results.sodium.length -1].unit,
        time: moment(this.props.results.sodium[this.props.results.sodium.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.sodium[this.props.results.sodium.length -1].abnormal ? 'red' : 'black'
      }
    }
    else na = empty;
    if (this.props.results.potassium && this.props.results.potassium.length) {
      k = {
        title: 'Potassium', 
        value: this.props.results.potassium[this.props.results.potassium.length -1].value,
        unit: this.props.results.potassium[this.props.results.potassium.length -1].unit,
        time: moment(this.props.results.potassium[this.props.results.potassium.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.potassium[this.props.results.potassium.length -1].abnormal ? 'red' : 'black'
      }
    }
    else k = empty;
    if (this.props.results.chloride && this.props.results.chloride.length) {
      cl = {
        title: 'Chloride', 
        value: this.props.results.chloride[this.props.results.chloride.length -1].value,
        unit: this.props.results.chloride[this.props.results.chloride.length -1].unit,
        time: moment(this.props.results.chloride[this.props.results.chloride.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.chloride[this.props.results.chloride.length -1].abnormal ? 'red' : 'black'
      }
    }
    else cl = empty;
    if (this.props.results.co2 && this.props.results.co2.length) {
      co2 = {
        title: 'Carbon Dioxide', 
        value: this.props.results.co2[this.props.results.co2.length -1].value,
        unit: this.props.results.co2[this.props.results.co2.length -1].unit,
        time: moment(this.props.results.co2[this.props.results.co2.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.co2[this.props.results.co2.length -1].abnormal ? 'red' : 'black'
      }
    }
    else co2 = empty;
    if (this.props.results.ureaNitrogen && this.props.results.ureaNitrogen.length) {
      bun = {
        title: 'Urea Nitrogen', 
        value: this.props.results.ureaNitrogen[this.props.results.ureaNitrogen.length -1].value,
        unit: this.props.results.ureaNitrogen[this.props.results.ureaNitrogen.length -1].unit,
        time: moment(this.props.results.ureaNitrogen[this.props.results.ureaNitrogen.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.ureaNitrogen[this.props.results.ureaNitrogen.length -1].abnormal ? 'red' : 'black'
      }
    }
    else bun = empty
    if (this.props.results.creatinine && this.props.results.creatinine.length) {
      cr = {
        title: 'Creatinine', 
        value: this.props.results.creatinine[this.props.results.creatinine.length -1].value,
        unit: this.props.results.creatinine[this.props.results.creatinine.length -1].unit,
        time: moment(this.props.results.creatinine[this.props.results.creatinine.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.creatinine[this.props.results.creatinine.length -1].abnormal ? 'red' : 'black'
      }
    }
    else cr = empty;
    if (this.props.results.glucose && this.props.results.glucose.length) {
      glucose = {
        title: 'Glucose', 
        value: this.props.results.glucose[this.props.results.glucose.length -1].value,
        unit: this.props.results.glucose[this.props.results.glucose.length -1].unit,
        time: moment(this.props.results.glucose[this.props.results.glucose.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.glucose[this.props.results.glucose.length -1].abnormal ? 'red' : 'black'
      }
    }
    else glucose = empty;
    this.setState({
      na: na,
      k: k,
      cl: cl,
      co2: co2,
      bun: bun,
      cr: cr,
      glucose: glucose
    })
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.results !== prevProps.results) {
      this.assignToState();
    }
  }

  render() {
    return (
      <div>
        <span className="labTitles">Basic Metabolic Panel</span>
        <svg
          preserveAspectRatio="xMinYMid"
          x="0"
          y="0"
          viewBox="0 0 100 20"
          style={{ overflow: "hidden" }}
        >
          <g stroke="grey" strokeWidth="0.5">
            <line
              x1={this.x(0)}
              x2={this.x(0.75)}
              y1={this.y(0.5)}
              y2={this.y(0.5)}
            />
            <line
              x1={this.x(0.25)}
              x2={this.x(0.25)}
              y1={this.y(1)}
              y2={this.y(0)}
            />
            <line
              x1={this.x(0.5)}
              x2={this.x(0.5)}
              y1={this.y(1)}
              y2={this.y(0)}
            />
            <line
              x1={this.x(0.75)}
              x2={this.x(0.75 + this.xDist)}
              y1={this.y(0.5)}
              y2={this.y(1)}
            />
            <line
              x1={this.x(0.75)}
              x2={this.x(0.75 + this.xDist)}
              y1={this.y(0.5)}
              y2={this.y(0)}
            />
          </g>
          <g fontSize={this.fontSize}>
            <text
              x={this.x(0.25 / 2)}
              y={this.y(0.75)}
              fill={this.state.na.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip2"
            >
              {this.state.na.value}
              <title>
                {this.state.na.title}
                {"\n"}
                {this.state.na.unit}
                {"\n"}
                {this.state.na.time}
              </title>
            </text>
            <text
              x={this.x((0.25 + 0.5) / 2)}
              y={this.y(0.75)}
              fill={this.state.cl.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip"
            >
              {this.state.cl.value}
              <title>
                {this.state.cl.title}
                {"\n"}
                {this.state.cl.unit}
                {"\n"}
                {this.state.cl.time}
              </title>
            </text>
            <text
              x={this.x((0.5 + 0.75) / 2)}
              y={this.y(0.75)}
              fill={this.state.bun.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip"
            >
              {this.state.bun.value}
              <title>
                {this.state.bun.title}
                {"\n"}
                {this.state.bun.unit}
                {"\n"}
                {this.state.bun.time}
              </title>
            </text>
            <text
              x={this.x(0.25 / 2)}
              y={this.y(0.25)}
              fill={this.state.k.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip"
            >
              {this.state.k.value}
              <title>
                {this.state.k.title}
                {"\n"}
                {this.state.k.unit}
                {"\n"}
                {this.state.k.time}
              </title>
            </text>
            <text
              x={this.x((0.25 + 0.5) / 2)}
              y={this.y(0.25)}
              fill={this.state.co2.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip"
            >
              {this.state.co2.value}
              <title>
                {this.state.co2.title}
                {"\n"}
                {this.state.co2.unit}
                {"\n"}
                {this.state.co2.time}
              </title>
            </text>
            <text
              x={this.x((0.5 + 0.75) / 2)}
              y={this.y(0.25)}
              fill={this.state.cr.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip"
            >
              {this.state.cr.value}
              <title>
                {this.state.cr.title}
                {"\n"}
                {this.state.cr.unit}
                {"\n"}
                {this.state.cr.time}
              </title>
            </text>
            <text
              x={this.x(0.75 + 0.03)}
              y={this.y(0.5)}
              fill={this.state.glucose.interpretation}
              textAnchor="start"
              alignmentBaseline="central"
              data-tip
              data-for="registerTip"
            >
              {this.state.glucose.value}
              <title>
                {this.state.glucose.title}
                {"\n"}
                {this.state.glucose.unit}
                {"\n"}
                {this.state.glucose.time}
              </title>
            </text>
          </g>
        </svg>
      </div>
    );
  }
}