let labTitles = {
    //basic metabolic panel
    chloride: { title: 'All Chloride Results' },
    co2: { title: 'All Cardbon Dioxide Results' },
    creatinine: { title: 'All Creatinine Results' },
    glucose: { title: 'All Glucose Results' },
    sodium: { title: 'All Sodium Results' },
    potassium: { title: 'All Potassium Results' },
    ureaNitrogen: { title: 'All Urea Nitrogen Results' },
    // coagulation
    pt: { title: 'All Prothrombin Time (PT) Results' },
    ptt: { title: 'All Partial Thromboplastin Time (PTT) Results' },
    inr: { title: 'All Internationl Normalized Ratio (INR) Results' },
    // complete blood count
    hematocrit: { title: 'All Hematocrit Results' },
    hemoglobin: { title: 'All Hemoglobin Results' },
    platelets: { title: 'All Platelets Results' },
    wbc: { title: 'All Leukocytes (White Blood Cell, WBC) Results' },
    // liver function
    albumin: { title: 'All Albumin Results' },
    alp: { title: 'All Alkaline Phosphatase (ALP) Results' },
    alt: { title: 'All Alanine Aminotransferase (ALT) Results' },
    ast: { title: 'All Aspartate Aminotransferase (AST) Results' },
    calcium: { title: 'All Calcium Results' },
    protein: { title: 'All Protein Results' },
    bilirubin: { title: 'All Bilirubin Results' },
    // cardiac panel
    bnp: { title: 'All B-Type Natriuretic Peptide Results' },
    ckmb: { title: 'All  Creatine Kinase Myocardial Band (CK-MB) Results' },
    troponin: { title: 'All Troponin Results' },
    // vitals signs
    height: { title: 'All Height Vital Signs' },
    weight:{ title: 'All Weight Vital Signs' },
    pulse: { title: 'All Pulse Vital Signs' },
    temp: { title: 'All Temperature Vital Signs' },
    tempF: { title: 'All Temperature Vital Signs' },
    o2: { title: 'All Oxygen Sturation Vital Signs' },
    bp: { title: 'All Blood Pressure Vital Signs' },
    sbp: { title: 'All Systolic Blood Pressure Vital Signs' },
    dbp: { title: 'All Diastolic Blood Pressure Vital Signs' },
  };
  
  export { labTitles };