import { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

class ProceduresTable extends Component  {

  render () {
    return (
      <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Procedure (code)</TableCell>
            <TableCell align="center">Performer</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.props.procedures.map((procedure) => (
            <TableRow
              key={procedure.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left">{JSON.stringify(procedure.performedPeriod)}</TableCell>
              <TableCell align="left">
                {procedure.code.text} ({procedure.code.coding[0].code})
              </TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    )
  }
}

export default ProceduresTable; 