import { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

class AllergiesTable extends Component {
  formatReaction = (reaction) => {
    console.log(reaction)
    let text = "";
    for (let i = 0; i < reaction.length; i++) {
      if (reaction[i].manifestation) {
        for (let j = 0; j < reaction[i].manifestation.length; j++) {
          if (reaction[i].manifestation[j].text) {
            if (reaction[i].severity) {
              text += `${reaction[i].manifestation[j].text} (${reaction[i].severity})` 
            }
            else {
              text += reaction[i].manifestation[j].text
            }
          }
        }
      }
    }
    return text;
  };

  render() {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Allergy Type</TableCell>
              <TableCell align="left">Specific Allergen</TableCell>
              <TableCell align="center">Criticality</TableCell>
              <TableCell align="center">Reaction</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.allergies.map((allergy) => (
              <TableRow
                key={allergy.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{allergy.category[0]}</TableCell>
                <TableCell align="left">
                  {allergy.code.text} ({allergy.code.coding[0].code})
                </TableCell>
                <TableCell align="center">{allergy.criticality}</TableCell>
                <TableCell align="center">
                  {this.formatReaction(allergy.reaction)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default AllergiesTable;
