import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { CCollapse } from "@coreui/react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import PolylineIcon from "@mui/icons-material/Polyline";
import TimelineIcon from "@mui/icons-material/Timeline";
import Tooltip from "@mui/material/Tooltip";
import Skeleton from "./C_Skeleton";
import ResultGraph from "./C_ResultGraph";
import ResultTable from "./C_ResultTable";
import ResultSearch from "./C_ResultSearch";
import ProceduresTable from "./C_ProceduresTable";
import ShowDiagnosticReport from "./C_ShowDiagnosticReport";
import ShowMedia from "./C_ShowMedia";
import moment from "moment";
import { referenceRanges } from "./helpers/referenceRanges";
import { abnormalCount } from "./helpers/abnormalCount";
import config from "./config";
import Close from "@mui/icons-material/Close";

const Head = styled(Paper)(({ theme }) => ({
  backgroundColor: "#008776",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  fontWeight: "bold",
}));

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  fontSize: "1.2em",
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const bigModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "90%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class Data extends Component {
  setVisible = function (visible, target) {
    // console.log(visible);
    let show = {};
    let hideAll = {
      bmp: false,
      coag: false,
      cbc: false,
      liver: false,
      other: false,
    };
    this.setState(hideAll);
    show[target] = false;
    if (visible) {
      this.setState(show);
    } else {
      show[target] = true;
      this.setState(show);
    }
  };

  iconState = function (visible) {
    if (!visible) return <ArrowRightIcon></ArrowRightIcon>;
    else return <ArrowDropDownIcon></ArrowDropDownIcon>;
  };

  state = {
    abnormalCount: {
      bmp: {
        numer: 0,
        denom: 0,
      },
      coag: {
        numer: 0,
        denom: 0,
      },
      cbc: {
        numer: 0,
        denom: 0,
      },
      cardiac: {
        numer: 0,
        denom: 0,
      },
      liver: {
        numer: 0,
        denom: 0,
      },
    },
    bmp: false,
    cardiac: false,
    coag: false,
    cbc: false,
    liver: false,
    latest: null,
    skeleton: false,
    modalMicrobiology: false,
    modalOther: false,
    modalImaging: false,
    modalProcedures: false,
    modalRadiologyReports: false,
    modalResultSearch: false,
  };

  handleOpen = (e) => {
    let settings = {};
    settings[e.target.id] = true;
    this.setState(settings);
  };

  handleClose = (e) => {
    this.setState({
      modalMicrobiology: false,
      modalOther: false,
      modalImaging: false,
      modalProcedures: false,
      modalRadiologyReports: false,
      modalResultSearch: false,
    });
  };

  removeNoTime = (time) => {
    return time.replace(" 0:00am", "");
  };

  flipIcon = () => {
    if (this.state.skeleton) {
      this.setState({
        skeleton: false,
      });
    } else {
      this.setState({
        skeleton: true,
      });
    }
  };

  formatLastResult = (array, digits, nodate) => {
    if (array && array.length) {
      let value = array[array.length - 1].value
        ? array[array.length - 1].value.toFixed &&
          array[array.length - 1].value.toFixed(digits)
        : "none";
      let unit = array[array.length - 1].unit
        ? array[array.length - 1].unit
        : "";
      let date = "";
      if (!value && array[array.length - 1].valueString) {
        value = array[array.length - 1].valueString;
      }
      if (array[array.length - 1].effectiveDateTime) {
        date = this.removeNoTime(
          moment(array[array.length - 1].effectiveDateTime).format(
            config.dateFormat
          )
        );
      }
      if (nodate === true) {
        if (array[array.length - 1].abnormal) {
          return <span className="abnormal">{value + " " + unit}</span>;
        } else {
          return value + " " + unit;
        }
      } else if (nodate === "only") {
        return this.removeNoTime(
          moment(array[array.length - 1].effectiveDateTime).format(
            config.dateFormat
          )
        );
      } else {
        return value + " " + unit + " " + date;
      }
    } else {
      if (nodate === "only") return "";
      else return "none";
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.latest !== prevState.latest) {
      let aCount = abnormalCount(this.props.results);
      this.setState({
        latest: this.props.latest,
        abnormalCount: aCount,
      });
    }
  }

  render() { 
    return (
      <Grid item xs={12} md={4} style={{ zIndex: 1000 }}>
        <Head>
          Data{" "}
          <input
            id="modalResultSearch"
            className="searchPanel"
            placeholder="Click to activate SEARCH"
            onClick={this.handleOpen}
          ></input>
          <TimelineIcon
            className="skeleton"
            onClick={this.flipIcon}
            style={{ display: this.state.skeleton ? "inline" : "none" }}
          />
          <PolylineIcon
            className="skeleton"
            onClick={this.flipIcon}
            style={{ display: this.state.skeleton ? "none" : "inline" }}
          />
        </Head>
        <Modal
          id="modalResultSearch"
          open={this.state.modalResultSearch}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={bigModalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Result Search
              <button className="closeButton" onClick={this.handleClose}>
                <Close />
              </button>
            </Typography>
            <ResultSearch results={this.props.results} />
          </Box>
        </Modal>
        <div style={{ display: this.state.skeleton ? "block" : "none" }}>
          <Skeleton results={this.props.results}></Skeleton>
        </div>
        <div style={{ display: this.state.skeleton ? "none" : "block" }}>
          <b>&nbsp;Lab Data: </b>{" "}
          <span className="actionOff">
            (latest lab:&nbsp;
            {moment(this.props.latest).format(config.dateFormat)})
          </span>
          <div>
            <Button onClick={() => this.setVisible(this.state.bmp, "bmp")}>
              {this.iconState(this.state.bmp)}
              Basic Metabolic Panel
            </Button>
            <StyledTooltip
              title={
                "Of the latest BMP labs, " +
                this.state.abnormalCount.bmp.numer +
                " were abnormal"
              }
              placement="right"
            >
              <span>
                (
                <span className="abnormal">
                  {this.state.abnormalCount.bmp.numer}
                </span>
                /{this.state.abnormalCount.bmp.denom})
              </span>
            </StyledTooltip>
            <CCollapse visible={this.state.bmp}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Carbon dioxide " +
                      this.formatLastResult(
                        this.props.results.co2,
                        referenceRanges.co2.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>CO2:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.co2,
                          referenceRanges.co2.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="co2"
                    results={this.props.results.co2}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.chloride,
                      referenceRanges.chloride.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Chloride:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.chloride,
                          referenceRanges.chloride.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="chloride"
                    results={this.props.results.chloride}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.creatinine,
                      referenceRanges.creatinine.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Creatinine:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.creatinine,
                          referenceRanges.creatinine.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="creatinine"
                    results={this.props.results.creatinine}
                    latest={this.props.latest}
                    gender={this.props.gender}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.glucose,
                      referenceRanges.glucose.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Glucose:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.glucose,
                          referenceRanges.glucose.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="glucose"
                    results={this.props.results.glucose}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.potassium,
                      referenceRanges.potassium.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Potassium:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.potassium,
                          referenceRanges.potassium.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="potassium"
                    results={this.props.results.potassium}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.sodium,
                      referenceRanges.sodium.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Sodium:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.sodium,
                          referenceRanges.sodium.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="sodium"
                    results={this.props.results.sodium}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.ureaNitrogen,
                      referenceRanges.ureaNitrogen.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Urea Nitrogen:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.ureaNitrogen,
                          referenceRanges.ureaNitrogen.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="ureaNitrogen"
                    results={this.props.results.ureaNitrogen}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
            </CCollapse>
          </div>
          <div>
            <Button onClick={() => this.setVisible(this.state.coag, "coag")}>
              {this.iconState(this.state.coag)}
              Coagulation
            </Button>
            <StyledTooltip
              title={
                "Of the latest coag labs, " +
                this.state.abnormalCount.coag.numer +
                " were abnormal"
              }
              placement="right"
            >
              <span>
                (
                <span className="abnormal">
                  {this.state.abnormalCount.coag.numer}
                </span>
                /{this.state.abnormalCount.coag.denom})
              </span>
            </StyledTooltip>
            <CCollapse visible={this.state.coag}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "International Normalized Ratio " +
                      this.formatLastResult(
                        this.props.results.inr,
                        referenceRanges.inr.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>INR:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.inr,
                          referenceRanges.inr.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="inr"
                    results={this.props.results.inr}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Prothrombin Time " +
                      this.formatLastResult(
                        this.props.results.pt,
                        referenceRanges.pt.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>PT:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.pt,
                          referenceRanges.pt.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="pt"
                    results={this.props.results.pt}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Partial Thromboplastin Time " +
                      this.formatLastResult(
                        this.props.results.ptt,
                        referenceRanges.ptt.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>PTT:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.ptt,
                          referenceRanges.ptt.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="ptt"
                    results={this.props.results.ptt}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
            </CCollapse>
          </div>
          <div>
            <Button onClick={() => this.setVisible(this.state.cbc, "cbc")}>
              {this.iconState(this.state.cbc)}
              Complete Blood Count
            </Button>{" "}
            <StyledTooltip
              title={
                "Of the latest CBC labs, " +
                this.state.abnormalCount.cbc.numer +
                " were abnormal"
              }
              placement="right"
            >
              <span>
                (
                <span className="abnormal">
                  {this.state.abnormalCount.cbc.numer}
                </span>
                /{this.state.abnormalCount.cbc.denom})
              </span>
            </StyledTooltip>
            <CCollapse visible={this.state.cbc}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.hemoglobin,
                      referenceRanges.hemoglobin.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Hemoglobin:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.hemoglobin,
                          referenceRanges.hemoglobin.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="hemoglobin"
                    results={this.props.results.hemoglobin}
                    latest={this.props.latest}
                    gender={this.props.gender}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.hematocrit,
                      referenceRanges.hematocrit.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Hematocrit:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.hematocrit,
                          referenceRanges.hematocrit.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="hematocrit"
                    results={this.props.results.hematocrit}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.platelets,
                      referenceRanges.platelets.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Platelets:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.platelets,
                          referenceRanges.platelets.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="platelets"
                    results={this.props.results.platelets}
                    latest={this.props.latest}
                    gender={this.props.gender}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Leukocytes " +
                      this.formatLastResult(
                        this.props.results.wbc,
                        referenceRanges.wbc.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>WBC:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.wbc,
                          referenceRanges.wbc.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="wbc"
                    results={this.props.results.wbc}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
            </CCollapse>
          </div>
          <div>
            <Button onClick={() => this.setVisible(this.state.liver, "liver")}>
              {this.iconState(this.state.liver)}
              Liver Function
            </Button>
            <StyledTooltip
              title={
                "Of the latest liver labs, " +
                this.state.abnormalCount.liver.numer +
                " were abnormal"
              }
              placement="right"
            >
              <span>
                (
                <span className="abnormal">
                  {this.state.abnormalCount.liver.numer}
                </span>
                /{this.state.abnormalCount.liver.denom})
              </span>
            </StyledTooltip>
            <CCollapse visible={this.state.liver}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.albumin,
                      referenceRanges.albumin.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Albumin:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.albumin,
                          referenceRanges.albumin.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="albumin"
                    results={this.props.results.albumin}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Alkaline Phosphatase " +
                      this.formatLastResult(
                        this.props.results.alp,
                        referenceRanges.alp.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>ALP:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.alp,
                          referenceRanges.alp.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="alp"
                    results={this.props.results.alp}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Alanine Aminotransferase " +
                      this.formatLastResult(
                        this.props.results.alt,
                        referenceRanges.alt.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>ALT:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.alt,
                          referenceRanges.alt.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="alt"
                    results={this.props.results.alt}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Aspartate Aminotransferase " +
                      this.formatLastResult(
                        this.props.results.ast,
                        referenceRanges.ast.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>AST:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.ast,
                          referenceRanges.ast.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="ast"
                    results={this.props.results.ast}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.ast,
                      referenceRanges.ast.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Bilirubin:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.bilirubin,
                          referenceRanges.bilirubin.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="bilirubin"
                    results={this.props.results.bilirubin}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.calcium,
                      referenceRanges.calcium.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Calcium:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.calcium,
                          referenceRanges.calcium.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="calcium"
                    results={this.props.results.calcium}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.protein,
                      referenceRanges.protein.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Protein:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.protein,
                          referenceRanges.protein.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="protein"
                    results={this.props.results.protein}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
            </CCollapse>
          </div>
          <div>
            <Button
              onClick={() => this.setVisible(this.state.cardiac, "cardiac")}
            >
              {this.iconState(this.state.cardiac)}
              Cardiac Enzymes
            </Button>
            <StyledTooltip
              title={
                "Of the latest cardiac labs, " +
                this.state.abnormalCount.cardiac.numer +
                " were abnormal"
              }
              placement="right"
            >
              <span>
                (
                <span className="abnormal">
                  {this.state.abnormalCount.cardiac.numer}
                </span>
                /{this.state.abnormalCount.cardiac.denom})
              </span>
            </StyledTooltip>
            <CCollapse visible={this.state.cardiac}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "B-type Natriuretic Peptide " +
                      this.formatLastResult(
                        this.props.results.bnp,
                        referenceRanges.bnp.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>BNP:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.bnp,
                          referenceRanges.bnp.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="bnp"
                    results={this.props.results.bnp}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={
                      "Creatine Kinase Myocardial Band " +
                      this.formatLastResult(
                        this.props.results.ckmb,
                        referenceRanges.ckmb.digits,
                        "only"
                      )
                    }
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>CK-MB:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.ckmb,
                          referenceRanges.ckmb.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="ckmb"
                    results={this.props.results.ckmb}
                    latest={this.props.latest}
                    gender={this.props.gender}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={4} style={{ fontSize: "1em" }}>
                  <StyledTooltip
                    title={this.formatLastResult(
                      this.props.results.troponin,
                      referenceRanges.troponin.digits,
                      "only"
                    )}
                    placement="right"
                  >
                    <span className="padLeft">
                      <b>Troponin:</b>{" "}
                      <span>
                        {this.formatLastResult(
                          this.props.results.troponin,
                          referenceRanges.troponin.digits,
                          true
                        )}
                      </span>
                    </span>
                  </StyledTooltip>
                </Grid>
                <Grid item xs={8}>
                  <ResultGraph
                    labType="troponin"
                    results={this.props.results.troponin}
                    latest={this.props.latest}
                    daysBack={this.props.daysBack}
                  />
                </Grid>
              </Grid>
            </CCollapse>
          </div>
        </div>

        <div
          style={{
            display: this.props.results.other.length ? "inline" : "none",
          }}
        >
          <Button id="modalOther" onClick={this.handleOpen}>
            {this.iconState(this.state.cbc)} Additional Labs
          </Button>
          <Badge badgeContent={this.props.results.other.length} color="primary">
            <InfoIcon color="action" />
          </Badge>
          <Modal
            id="modalOther"
            open={this.state.modalOther}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={bigModalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Additional Labs
                <button className="closeButton" onClick={this.handleClose}>
                  <Close />
                </button>
              </Typography>
              <ResultTable results={this.props.results.other}></ResultTable>
            </Box>
          </Modal>
        </div>
        <div>
          &nbsp;
          <b>Microbiology:&nbsp;</b>
          <span
            style={{
              display: this.props.otherData.microbiology.length
                ? "none"
                : "inline",
            }}
          >
            No microbiology data
          </span>
          <button
            id="modalMicrobiology"
            className="link"
            href="#"
            onClick={this.handleOpen}
            style={{
              display: this.props.otherData.microbiology.length
                ? "inline"
                : "none",
            }}
          >
            Microbiology
          </button>
          <Modal
            open={this.state.modalMicrobiology}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Module not active
              </Typography>
            </Box>
          </Modal>
        </div>
        <div>
          &nbsp;
          <b>Diagnostic Reports:&nbsp;</b>
          <span
            style={{
              display: this.props.otherData.radiology.length
                ? "none"
                : "inline",
            }}
          >
            No diagnostic reports
          </span>
          <button
            id="modalRadiologyReports"
            className="link"
            href="#"
            onClick={this.handleOpen}
            style={{
              display: this.props.otherData.radiology.length
                ? "inline"
                : "none",
            }}
          >
            {this.props.otherData.radiology.length} Reports Available
          </button>
          <Modal
            open={this.state.modalRadiologyReports}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={bigModalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {this.props.otherData.radiology &&
                  this.props.otherData.radiology.map((report, i) => (
                    <div key={"diagnosticReport" + i}>
                      <ShowDiagnosticReport report={report} />
                      <hr />
                    </div>
                  ))}
              </Typography>
            </Box>
          </Modal>
        </div>
        <div>
          &nbsp;
          <b>Scans &amp; Images:&nbsp;</b>
          <span
            style={{
              display: this.props.otherData.imaging.length ? "none" : "inline",
            }}
          >
            No imaging links available
          </span>
          <button
            id="modalImaging"
            className="link"
            href="#"
            onClick={this.handleOpen}
            style={{
              display: this.props.otherData.imaging.length ? "inline" : "none",
            }}
          >
            {this.props.otherData.imaging.length} Reports Available
          </button>
          <Modal
            open={this.state.modalImaging}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={bigModalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {this.props.otherData.imaging &&
                  this.props.otherData.imaging.map((report, i) => (
                    <div key={"diagnosticReport" + i}>
                      <ShowMedia report={report} />
                      <hr />
                    </div>
                  ))}
              </Typography>
            </Box>
          </Modal>
        </div>
        <div>
          &nbsp;
          <b>Procedures:&nbsp;</b>
          <span
            style={{
              display: this.props.otherData.procedures.length
                ? "none"
                : "inline",
            }}
          >
            No procedure data
          </span>
          <span
            style={{
              display: this.props.otherData.procedures.length
                ? "inline"
                : "none",
            }}
          >
            <button
              className="link"
              id="modalProcedures"
              onClick={this.handleOpen}
            >
              Procedures detail
            </button>
            <Badge
              badgeContent={this.props.otherData.procedures.length}
              color="primary"
            >
              <InfoIcon color="action" />
            </Badge>
          </span>
          <Modal
            open={this.state.modalProcedures}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={bigModalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Procedures
                <button className="closeButton" onClick={this.handleClose}>
                  <Close />
                </button>
              </Typography>
              <ProceduresTable
                procedures={this.props.otherData.procedures}
              ></ProceduresTable>
            </Box>
          </Modal>
        </div>
      </Grid>
    );
  }
}

export default Data;
