import { Component } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import moment from "moment";

class SearchResult extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <span
              style={{ display: this.props.results.length ? "none" : "block" }}
            >
              No results to display
            </span>
            <TableContainer
              component={Paper}
              style={{ display: this.props.results.length ? "block" : "none" }}
            >
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Last Name</TableCell>
                    <TableCell align="left">First Name</TableCell>
                    <TableCell align="center">DOB</TableCell>
                    <TableCell align="center">Last Updated</TableCell>
                    <TableCell align="left">Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.props.results.map((result) => (
                    <TableRow
                      key={result.resource.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {result.resource.name[0].family}
                      </TableCell>
                      <TableCell align="left">
                        {result.resource.name[0].given.join(" ")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(result.resource.birthDate).format("M/D/YYYY")}
                      </TableCell>
                      <TableCell align="center">
                        {moment(result.resource.meta.lastUpdated).format(
                          "M/D/YYYY H:mma"
                        )}
                      </TableCell>
                      <TableCell align="left">
                        <a
                          href={
                            window.location.href.replace('finder', '') +
                            "patient/" +
                            result.resource.id
                          }
                        >
                          {window.location.href.replace('finder', '')}patient/{result.resource.id}
                        </a>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        <Grid></Grid>
      </div>
    );
  }
}

export default SearchResult;