import React, { Component } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import SearchResult from "./search_results";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/joy/CircularProgress";
import axios from "axios";
import config from "./config";

// Git Info
import GitInfo from 'react-git-info/macro';

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const gitInfo = GitInfo();

export class PatientSearch extends Component {
  state = {
    date: "",
    lastName: "",
    loadState: false,
    results: [],
  };

  getData = (query) => {
    this.setState({
      loadState: true,
    });
    axios({
      method: "get",
      url: `${config.server}/Patient?${query}`,
      headers: {},
    }).then((res) => {
      console.log(res.data);
      if (res.data && res.data.entry && res.data.entry.length) {
        console.log(`${res.data.entry.length} data found!`);
        this.setState({
          results: res.data.entry,
        });
        setTimeout(() => {
          console.log(this.state);
          this.setState({
            loadState: false,
          });
        }, 100);
      } else if (res.data && res.data.total === 0) {
        this.setState({
          results: [],
          loadState: false,
        });
      } else {
        alert("error retrieving data");
        console.log(res);
        this.setState({
          results: [],
          loadState: false,
        });
      }
    });
  };

  search = () => {
    console.log(this.state);
    // notify user of issue
    if (
      !this.state.lastName &&
      (!this.state.date || this.state.date === "Invalid Date")
    ) {
      alert("You have not entered a last name or valid date");
    }
    // search for all patients (not directly exposed to users, for developers only)
    else if (this.state.lastName === "*" && !this.state.date) {
      this.getData("");
    }
    // name only search
    else if (this.state.lastName && !this.state.date) {
      this.getData(`family=${this.state.lastName}`);
    }
    // DOB only search
    else if (
      !this.state.lastName &&
      this.state.date &&
      this.state.date !== "Invalid Date"
    ) {
      this.getData(`birthdate=${this.state.date}`);
    }
    // dual search
    else if (
      this.state.lastName &&
      this.state.date &&
      this.state.date !== "Invalid Date"
    ) {
      this.getData(
        `family=${this.state.lastName}&birthdate=${this.state.date}`
      );
    } else {
      alert("search condition failed");
    }
  };

  handleNameChange = (e) => {
    this.setState({ lastName: e.target.value });
  };

  checkSearch = (e) => {
    //checks whether the pressed key is "Enter"
    if (e.code === "Enter") {
      this.search();
    }
  };

  render() {
    return (
      <div>
        <br />
        <br />
        <div>
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="outlined"
            value={this.state.lastName}
            onChange={this.handleNameChange}
            onKeyDown={this.checkSearch}
          />
        </div>
        <br />
        and/or
        <br />
        <br />
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Date of Birth"
              onChange={(date) => {
                const d = new Date(date).toJSON();
                if (
                  d &&
                  d.length &&
                  d.length > 10 &&
                  d.slice(0, 10) !== "1970-01-01"
                ) {
                  console.log(d.slice(0, 10));
                  this.setState({ date: d.slice(0, 10) });
                } else {
                  this.setState({ date: "" });
                }
              }}
            />
          </LocalizationProvider>
        </div>
        <br />
        <div>
          <Button variant="contained" onClick={this.search}>
            Search
          </Button>
        </div>
        <hr />
        <div>
          <h3>Search Results</h3>
          <SearchResult results={this.state.results} />
        </div>
        <hr />
        <div>
          Developed and hosted by More Informatics, Inc in collaboration with
          Dr. Stephanie Mueller and Dr. Lipika Samal of Harvard Medical School &
          MassGeneralBrigham. contact{" "}
          <a href="mailto:johnd@moreinformatics.com">
            johnd@moreinformatics.com
          </a>{" "}
          for questions.
        </div>
        <div>
          Version: {gitInfo.commit.shortHash}
        </div>
        <Modal
          id="modalLoading"
          open={this.state.loadState}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Searching for data...
              <CircularProgress />
              This window will close when data received
            </Typography>
          </Box>
        </Modal>
      </div>
    );
  }
}
