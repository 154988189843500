import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import SparklinesText from './SparklinesText';
import SparklinesLine from './SparklinesLine';
import SparklinesSpots from './SparklinesSpots';
import SparklinesInteractiveLayer from './SparklinesInteractiveLayer';
import moment from 'moment';
// import SparklinesNormalBand from './SparklineNormalBand';

const dataToPoints = function ({ data, width = 1, height = 1, margin = 0, max = Math.max.apply(Math, data.y), min = Math.min.apply(Math, data.y), referenceRange, latest, gender, labType, daysBack}) {

  // console.log(labType)

  let begin = moment(latest).subtract(daysBack, 'days')
  data.diff = [];

  let minutes = (daysBack * 24 * 60);
  for (let i = 0; i < data.dates.length; i++) {
    let end = moment(data.dates[i]);
    let datediff = moment.duration(end.diff(begin)).asMinutes();
    data.diff.push(datediff / minutes);
  }
  
  if ((!referenceRange.low && referenceRange.low !== 0) || !referenceRange.high) {
    referenceRange = referenceRange[gender];
  }

  // Adding code to make sure reference range always displays
  if (referenceRange && (referenceRange.low || referenceRange.low === 0) && min > referenceRange.low) {
    min = referenceRange.low
  }
  if (referenceRange && referenceRange.high && max < referenceRange.high) {
    max = referenceRange.high
  }

  const vfactor = (height - margin * 2) / ((max - min) || 2);
  const hfactor = (width - margin * 2);

  let output = data.y.map((d, i) => ({
    x: data.diff[i] * hfactor  + margin,
    y: (max - d) * vfactor + margin
  }))

  // console.log(data)

  return output;
}

class Sparklines extends PureComponent {

  static propTypes = {
    data: PropTypes.object,
    limit: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    svgWidth: PropTypes.number,
    svgHeight: PropTypes.number,
    preserveAspectRatio: PropTypes.string,
    margin: PropTypes.number,
    style: PropTypes.object,
    min: PropTypes.number,
    max: PropTypes.number,
    onMouseMove: PropTypes.func
  };

  static defaultProps = {
    data: [],
    width: 240,
    height: 60,
    //Scale the graphic content of the given element non-uniformly if necessary such that the element's bounding box exactly matches the viewport rectangle.
    preserveAspectRatio: 'none', //https://www.w3.org/TR/SVG/coords.html#PreserveAspectRatioAttribute
    margin: 2
  };

  render() {
    const { data, width, height, svgWidth, svgHeight, preserveAspectRatio, margin, style, max, min, referenceRange, latest, gender, labType, daysBack } = this.props;

    if (data.y.length === 0) return null;

    const points = dataToPoints({ data, width, height, margin, max, min, referenceRange, latest, gender, labType, daysBack});

    const svgOpts = { style: style, viewBox: `0 0 ${width} ${height}`, preserveAspectRatio: preserveAspectRatio };
    if (svgWidth > 0) svgOpts.width = svgWidth;
    if (svgHeight > 0) svgOpts.height = svgHeight;

    return (
      <svg {...svgOpts}>
        <defs>
          <filter x="0" y="0" width="1" height="1" id={labType}>
            <feFlood floodColor="#6c6c6c" result="bg" />
            <feMerge>
              <feMergeNode in="bg" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>
        {
          React.Children.map(this.props.children, function (child) {
            return React.cloneElement(child, { data, points, width, height, margin });
          })
        }
      </svg>
    );
  }
}

export { Sparklines, SparklinesLine, SparklinesText, SparklinesInteractiveLayer, SparklinesSpots}