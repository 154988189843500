import { useRef } from "react";
import "./styles.css"; // required styles
import "./tabulator_bootstrap.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from 'moment';
import config from './config';

let columns = [
  { title: "Description", field: "description",  hozAlign: "left" },
  { title: "Date", field: "date",width: 300,  hozAlign: "center"},
  { title: "Value", field: "value",width: 300, cssClass: 'data-bold',  hozAlign: "center"},
  { title: "Interpretation", field: "interpretation", width: 150,  hozAlign: "center" },
  { title: "Performer", field: "performer", width: 150 },
];

export default function ResultSearch({ results }) {
  let ref = useRef(null);
  let consolidatedResults = [];
  let data = [];

  for (let key in results) {
    if (results.hasOwnProperty(key)) {
      consolidatedResults = [...consolidatedResults, ...results[key]]
    }
  }

  for (let i = 0; i < consolidatedResults.length; i++) {
    let result = consolidatedResults[i];
    let item = {
      description: "",
      date: "",
      value: "",
      interpretation: "", 
      performer: ""
    }
    item.description = `${result.text} (${result.LOINC})`
    if (result.effectiveDateTime !== "Time Missing") item.date = moment(result.effectiveDateTime).format(config.dateFormat);
    if (result.valueString) {
      item.value = result.valueString;
    }
    else if (result.value && result.unit) {
      item.value = `${result.value} ${result.unit ? result.unit : ''}`
    }
    item.interpretation = result.interpretation;
    data.push(item);
  }

  const updateFilter = function (e, ref) {
    ref.current.setFilter("description", "like", e.target.value);
  };

  return (
    <div>
      <input
        id="filter-value"
        type="text"
        className="form-control"
        style={{ display: "inline-block", fontSize: "1.5rem" }}
        placeholder="Search Results"
        onChange={function (e) {
          updateFilter(e, ref);
        }}
      />
      <br />
      <br />
      <ReactTabulator
        onRef={(r) => (ref = r)}
        columns={columns}
        data={data}
        events={{
          rowClick: function (e, row) {},
        }}
      />
    </div>
  );
}
