import React, { Component } from "react";
import {
  Sparklines,
  SparklinesLine, 
  SparklinesInteractiveLayer,
} from "./sparklines/Sparklines";
import { referenceRanges } from "./helpers/referenceRanges";
import { labTitles } from "./helpers/lab_titles";
import { NormalBand } from "./sparklines/SparklinesNormalBand";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TableViewIcon from "@mui/icons-material/TableView";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Close from "@mui/icons-material/Close";
import ResultTable from "./C_ResultTable";
import config from "./config";
import moment from "moment";

const bigModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class Result extends Component {
  state = {
    modalOpen: false,
  };

  formatData = (results, type, unitOverride) => {
    // console.log(type)
    let data = {
      dates: [],
      y: [],
      unit: unitOverride ? unitOverride : referenceRanges[type].unit,
      min: false,
      max: false,
    };
    if (!results || !results.length) {
      return data;
    } else {
      let begin = moment(this.props.latest).subtract(
        config.sparklines.daysBack,
        "days"
      );
      for (let i = 0; i < results.length; i++) {
        if (
          results[i].effectiveDateTime !== "Time Missing" &&
          this.checkDate(results[i].effectiveDateTime, begin) &&
          results[i].value &&
          results[i].value.toFixed
        ) {
          data.dates.push(results[i].effectiveDateTime);
          if (!data.min || results[i].value < data.min)
            data.min = results[i].value;
          if (!data.max || results[i].value > data.max)
            data.max = results[i].value;
          data.y.push(results[i].value.toFixed(referenceRanges[type].digits));
        }
      }
      return data;
    }
  };

  checkDate = (time, begin) => {
    if (moment(time).isAfter(begin)) {
      return true;
    }
    return false;
  };

  openModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
    });
  };

  determineText = (results) => {
    if (!results || !results.length) {
      return "No data available";
    } else {
      let begin = moment(this.props.latest).subtract(
        config.sparklines.daysBack,
        "days"
      );
      let dataIncluded = false;
      for (let i = 0; i < results.length; i++) {
        if (
          results[i].effectiveDateTime !== "Time Missing" &&
          this.checkDate(results[i].effectiveDateTime, begin)
        ) {
          dataIncluded = true;
          break;
        }
      }
      if (dataIncluded) {
        return "";
      } else {
        // return `Older data available but not in the past ${config.sparklines.daysBack} days`<Modal/>
        return (
          <span>
            Older data not in past {config.sparklines.daysBack} days{" "}
            <OpenInNewIcon style={{ color: "#1975d2" }} />{" "}
          </span>
        );
      }
    }
  };

  render() {
    return (
      <span>
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item xs={2} style={{display: this.props.results.length ? 'inline' : 'none'}}>
            <TableViewIcon className="bigIcon" color="action" onClick={this.openModal} style={{cursor:'pointer'}}/>
          </Grid>
          <Grid item xs={10}>
            <Sparklines
              data={this.formatData(this.props.results, this.props.labType)}
              width={config.sparklines.width}
              height={
                this.props.height ? this.props.height : config.sparklines.height
              }
              margin={config.sparklines.margin}
              referenceRange={referenceRanges[this.props.labType]}
              latest={this.props.latest}
              gender={this.props.gender}
              labType={
                this.props.labType === "tempF" ? "temp" : this.props.labType
              }
              daysBack={this.props.daysBack}
            >
              <SparklinesLine style={{ fill: "none", strokeOpacity: .25, strokeWidth: '.8' }}/>
              <NormalBand
                originalData={this.formatData(
                  this.props.results,
                  this.props.labType
                )}
                referenceRange={referenceRanges[this.props.labType]}
                gender={this.props.gender}
                height={
                  this.props.height
                    ? this.props.height
                    : config.sparklines.height
                }
                width={config.sparklines.width}
              />
              <SparklinesInteractiveLayer
                originalData={this.formatData(
                  this.props.results,
                  this.props.labType
                )}
                style={{ cursor: "pointer" }}
                onClick={this.openModal}
                width={config.sparklines.width}
                labType={this.props.labType}
              />
            </Sparklines>
            <span
              style={{ paddingLeft: "10%", cursor: "pointer" }}
              onClick={this.openModal}
            >
              <b>
                <i>{this.determineText(this.props.results)}</i>
              </b>
            </span>
            <Modal
              id={this.props.labType}
              open={this.state.modalOpen}
              onClose={this.closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={bigModalStyle}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {labTitles[this.props.labType].title}
                  <button className="closeButton" onClick={this.closeModal}>
                    <Close />
                  </button>
                </Typography>
                <ResultTable results={this.props.results}></ResultTable>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </span>
    );
  }
}

export default Result;


/**
 <SparklinesSpots size={2} />
 */