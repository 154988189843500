import { Component } from 'react';

  

class ShowNote extends Component  {
  render () {
    return (
      <div style={{whiteSpace: "pre-wrap"}}>
        {atob(this.props.note.content[0].attachment.data)}
      </div> 
    )
  }
}

export default ShowNote; 