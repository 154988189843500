import React, { Component } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import ResultGraph from "./C_ResultGraph";
import { faSquare, faSquareCheck } from "@fortawesome/free-regular-svg-icons";
import config from "./config";
import { referenceRanges } from "./helpers/referenceRanges";
import { IHTObservation } from "./helpers/IHTObservation";
import Tooltip from "@mui/material/Tooltip";
import axios from "axios";
import moment from "moment";

const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);

const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  fontSize: "1.2em",
}));

const Head = styled(Paper)(({ theme }) => ({
  backgroundColor: "#008776",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  fontWeight: "bold",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: "1px",
  textAlign: "left",
  color: "black",
}));

const calcAge = function (birthDate) {
  if (!birthDate) return "";
  else return moment().diff(birthDate, "years");
};

class Vitals extends Component {
  state = {
    age: "",
    illnessSeverity: "",
    modalAction: false,
    modalOther: false,
    modalSave: false,
    otherComplaint: "",
    primaryComplaint: "",
    reason: "",
    actions: {
      cardiology: faSquare,
      oncology: faSquare,
      mri: faSquare,
      ct: faSquare,
      xray: faSquare,
      ultrasound: faSquare,
      endoscopy: faSquare,
      eeg: faSquare,
      bypass: faSquare,
      other1: faSquare,
      other2: faSquare,
      other3: faSquare,
    },
    actionNames: {
      cardiology: "Cardiology Consult",
      oncology: "Oncology Consult",
      mri: "MRI Order",
      ct: "CT Order",
      xray: "X-Ray Order",
      ultrasound: "Ultrasound Order",
      endoscopy: "Endoscopy",
      eeg: "EEG",
      bypass: "Bypass",
    },
    otherActions: {
      other1: "",
      other2: "",
      other3: "",
    },
    othersSelected: false,
    actionDisplay: "",
    temp: [],
    problems: [],
    unit: "Cel",
  };

  illnessChange = (e) => {
    this.setState({ illnessSeverity: e.target.value });
  };

  reasonChange = (e) => {
    this.setState({ reason: e.target.value });
  };

  primaryComplaint = (e) => {
    if (e.target.value === "Set new primary complaint") {
      this.handleOtherOpen();
    } else {
      this.setState({ primaryComplaint: e.target.value });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.Patient.birthDate !== prevProps.Patient.birthDate) {
      if (this.props.custom && this.props.custom.length) {
        let problems = JSON.parse(JSON.stringify(this.props.problems))
        let parts = this.props.custom[0].valueString.split('|');
        let newIllnessSeverity = parts[0];
        let newReason = parts[1];
        let primaryComplaint = parts[2]
        if (primaryComplaint) {
          let newProblem = {
            id: "user-defined",
            code: {
              text: primaryComplaint,
            },
          };
          problems.push(newProblem);
        }
        let newAction = parts[3];
        let actionDisplays = newAction.split(',');
        let actions = {
          cardiology: faSquare,
          oncology: faSquare,
          mri: faSquare,
          ct: faSquare,
          xray: faSquare,
          ultrasound: faSquare,
          endoscopy: faSquare,
          eeg: faSquare,
          bypass: faSquare,
          other1: faSquare,
          other2: faSquare,
          other3: faSquare
        }
        // console.log(actionDisplays);
        for (let key in this.state.actionNames) {
          if (this.state.actionNames.hasOwnProperty(key)) {
            for (let i = 0; i < actionDisplays.length; i++) {
              if (this.state.actionNames[key] === actionDisplays[i].trim()) {
                actions[key] = faSquareCheck; 
                break;
              }
            }
          }
        }
        this.setState({
          actions: actions,
          age: calcAge(this.props.Patient.birthDate),
          illnessSeverity: newIllnessSeverity,
          primaryComplaint: primaryComplaint,
          reason: newReason,
          temp: this.props.vitals.temp,
          problems: problems,
          actionDisplay: newAction
        });
      }
      else {
        this.setState({
          age: calcAge(this.props.Patient.birthDate),
          temp: this.props.vitals.temp,
          problems: JSON.parse(JSON.stringify(this.props.problems)),
        });  
      }
    }
  }

  flipAction = (e) => {
    let target = e.target.id;
    if (!target) {
      target = e.target.parentNode.id;
    }
    if (this.state.actions[target].iconName === "square-check") {
      let state = {
        actions: this.state.actions,
      };
      state.actions[target] = faSquare;
      this.setState(state);
    } else {
      let state = {
        actions: this.state.actions,
      };
      state.actions[target] = faSquareCheck;
      this.setState(state);
    }
  };

  handleActionOpen = () => {
    let actions = this.state.actions;
    let priorActions = false;
    if (this.state.otherActions.other1) {
      actions.other1 = faSquareCheck;
      priorActions = true;
    } else actions.other1 = faSquare;
    if (this.state.otherActions.other2) {
      actions.other2 = faSquareCheck;
      priorActions = true;
    } else actions.other2 = faSquare;
    if (this.state.otherActions.other3) {
      actions.other3 = faSquareCheck;
      priorActions = true;
    } else actions.other3 = faSquare;
    this.setState({
      modalAction: true,
      actions: actions,
      priorActions: priorActions,
    });
  };

  handleActionClose = (e) => {
    let newDisplay = "";
    let count = 0;
    let newOtherState = {
      other1: "",
      other2: "",
      other3: "",
    };
    for (let key in this.state.actions) {
      if (this.state.actions.hasOwnProperty(key)) {
        if (
          this.state.actions[key] &&
          this.state.actions[key].iconName &&
          this.state.actions[key].iconName === "square-check"
        ) {
          if (newDisplay !== "" && !key.includes("other")) newDisplay += ", ";
          if (this.state.actionNames[key]) {
            newDisplay += this.state.actionNames[key];
          }
        }
      }
    }
    if (this.state.otherSelected) {
      if (
        this.state.actions.other1.iconName === "square" &&
        this.state.actions.other2.iconName === "square" &&
        this.state.actions.other3.iconName === "square"
      ) {
        count = 0;
      } else {
        if (
          this.state.actions.other1.iconName !== "square" &&
          this.state.otherActions.other1
        ) {
          if (newDisplay !== "") newDisplay += ", ";
          newOtherState.other1 = this.state.otherActions.other1;
          newDisplay += this.state.otherActions.other1;
          count++;
        }
        if (
          this.state.actions.other2.iconName !== "square" &&
          this.state.otherActions.other2
        ) {
          if (newDisplay !== "") newDisplay += ", ";
          newOtherState.other2 = this.state.otherActions.other2;
          newDisplay += this.state.otherActions.other2;
          count++;
        }
        if (
          this.state.actions.other3.iconName !== "square" &&
          this.state.otherActions.other3
        ) {
          if (newDisplay !== "") newDisplay += ", ";
          newOtherState.other3 = this.state.otherActions.other3;
          newDisplay += this.state.otherActions.other3;
          count++;
        }
      }
    } else {
      if (this.state.otherActions.other1) {
        if (newDisplay !== "") newDisplay += ", ";
        newOtherState.other1 = this.state.otherActions.other1;
        newDisplay += this.state.otherActions.other1;
        count++;
      }
      if (this.state.otherActions.other2) {
        if (newDisplay !== "") newDisplay += ", ";
        newOtherState.other2 = this.state.otherActions.other2;
        newDisplay += this.state.otherActions.other2;
        count++;
      }
      if (this.state.otherActions.other3) {
        if (newDisplay !== "") newDisplay += ", ";
        newOtherState.other3 = this.state.otherActions.other3;
        newDisplay += this.state.otherActions.other3;
        count++;
      }
    }
    this.setState({
      modalAction: false,
      actionDisplay: newDisplay,
      otherActions: newOtherState,
      otherSelected: count ? true : false,
    });
  };

  handleOtherAction = (e) => {
    let actions = {};
    if (e.target.value) {
      actions[e.target.id] = e.target.value;
    } else {
      actions[e.target.id] = false;
    }
    if (e.target.id !== "other1")
      actions["other1"] = this.state.otherActions.other1;
    if (e.target.id !== "other2")
      actions["other2"] = this.state.otherActions.other2;
    if (e.target.id !== "other3")
      actions["other3"] = this.state.otherActions.other3;
    this.setState({
      otherActions: actions,
    });
  };

  handleOtherOpen = (e) => {
    this.setState({
      modalOther: true,
      primaryComplaint: "",
      problems: JSON.parse(JSON.stringify(this.props.problems)),
      newProblem: null,
    });
    console.log(this.state.problems);
  };

  handleOtherClose = (e) => {
    let newProblems = this.state.problems;
    if (this.state.newProblem) {
      newProblems.push(this.state.newProblem);
      this.setState({
        problems: newProblems,
        primaryComplaint: this.state.newProblem.code.text
          ? this.state.newProblem.code.text
          : "",
        modalOther: false,
      });
    }
    else {
      this.setState({
        modalOther: false 
      });
    }
  };

  updateComplaint = (e) => {
    let newProblem = {
      id: "user-defined",
      code: {
        text: e.target.value,
      },
    };
    this.setState({
      newProblem: newProblem,
    });
  };

  formatLastVital = (array, digits, nodate) => {
    if (
      array &&
      array.length &&
      array[array.length - 1].value &&
      array[array.length - 1].value.toFixed
    ) {
      let value = array[array.length - 1].value
        ? array[array.length - 1].value.toFixed(digits)
        : "none";
      let unit = array[array.length - 1].unit
        ? array[array.length - 1].unit
        : "";
      let date = "";
      if (array[array.length - 1].effectiveDateTime) {
        date = moment(array[array.length - 1].effectiveDateTime).format(
          config.dateFormat
        );
      }
      if (nodate === "only") {
        return date;
      } else if (nodate) {
        return value + " " + unit;
      } else {
        return value + " " + unit + " " + date;
      }
    } else {
      return "none";
    }
  };

  convertTemp = (array, convertToFarenheit) => {
    for (let i = 0; i < array.length; i++) {
      if (convertToFarenheit) {
        array[i].value = (array[i].value * 9) / 5 + 32;
        array[i].unit = "F";
      } else {
        array[i].value = ((array[i].value - 32) * 5) / 9;
        array[i].unit = "Cel";
      }
    }
    return array;
  };

  buttonConvert = (e) => {
    let convertToFarenheit = false;
    if (e.target.value === "Cel → F") convertToFarenheit = true;
    let newTemps = this.convertTemp(this.state.temp, convertToFarenheit);
    this.setState({
      temp: newTemps,
      unit: convertToFarenheit ? "F" : "Cel",
    });
  };

  formatLastBP = (type, array, digits, nodate) => {
    if (array && array.length) {
      let component = array[array.length - 1].component;
      if (component) {
        for (let i = 0; i < component.length; i++) {
          if (component[i].code.coding[0].code === "8480-6" && type === "sys") {
            array[array.length - 1].value = component[i].valueQuantity.value;
            array[array.length - 1].unit = component[i].valueQuantity.unit;
            return this.formatLastVital(array, digits, nodate);
          } else if (
            component[i].code.coding[0].code === "8462-4" &&
            type === "dia"
          ) {
            array[array.length - 1].value = component[i].valueQuantity.value;
            array[array.length - 1].unit = component[i].valueQuantity.unit;
            return this.formatLastVital(array, digits, nodate);
          }
        }
        return "none";
      }
    } else {
      return "none";
    }
  };

  sysFormatBP = (bp) => {
    let newData = [];
    for (let i = 0; i < bp.length; i++) {
      let newResult = {
        LOINC: "",
        text: "",
        id: bp[i].id + "systolic",
        effectiveDateTime: bp[i].effectiveDateTime,
        value: "",
        unit: "mmHg",
      };
      let component = bp[i].component;
      newResult.LOINC = "8480-6";
      newResult.text = "Systolic Blood Pressure";
      for (let j = 0; j < component.length; j++) {
        if (component[j].code.coding[0].code === "8480-6") {
          newResult.value = component[j].valueQuantity.value;
          break;
        }  
      }
      newData.push(newResult);
    }
    return newData;
  };

  diaFormatBP = (bp) => {
    let newData = [];
    for (let i = 0; i < bp.length; i++) {
      let newResult = {
        LOINC: "",
        text: "",
        id: bp[i].id + "diastolic",
        effectiveDateTime: bp[i].effectiveDateTime,
        value: "",
        unit: "mmHg",
      };
      let component = bp[i].component;
      newResult.LOINC = "8462-4";
      newResult.text = "Diatolic Blood Pressure";
      for (let j = 0; j < component.length; j++) {
        if (component[j].code.coding[0].code === "8462-4") {
          newResult.value = component[j].valueQuantity.value;
          break;
        }
      }
      newData.push(newResult);
    }
    return newData;
  };

  saveData = () => {
    this.setState({
      modalSave: true,
    });
    let newCustomObservation = IHTObservation(this.state, this.props.Patient);
    console.log(newCustomObservation);
    if (this.props.custom && this.props.custom.length) {
      axios({
        method: "delete",
        url: `${config.server}/Observation/${this.props.custom[0].id}`,
      }).then((res) => {
        console.log(res);
        if (res.status === 200) {
          axios({
            method: "post",
            url: `${config.server}/Observation/`,
            data: newCustomObservation,
            headers: {},
          }).then((res) => {
            console.log(res);
            if (res.status === 201) {
              alert("data saved");
              this.setState({
                modalSave: false,
              });
            } else {
              alert("data saving failed. Please refresh browser");
            }
          });
        } else {
          alert("data saving failed. Please refresh browser");
        }
      });
    } else {
      axios({
        method: "post",
        url: `${config.server}/Observation/`,
        data: newCustomObservation,
        headers: {},
      }).then((res) => {
        console.log(res);
        if (res.status === 201) {
          alert("data saved");
          this.setState({
            modalSave: false,
          });
        } else {
          alert("data saving failed. Please refresh browser");
        }
      });
    }
  };

  handleSaveClose = () => {};

  render() {
    return (
      <Grid item xs={12} md={4}>
        <Head>Vital Signs</Head>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} style={{ fontSize: "1em" }}>
                      <StyledTooltip
                        title={this.formatLastVital(
                          this.props.vitals.weight,
                          referenceRanges.weight.digits,
                          "only"
                        )}
                        placement="right"
                      >
                        <span>
                          <b>Height / Weight: </b>{" "}
                          <span>
                            {this.formatLastVital(
                              this.props.vitals.height,
                              referenceRanges.height.digits,
                              true
                            )}
                            &nbsp;/&nbsp;
                            {this.formatLastVital(
                              this.props.vitals.weight,
                              referenceRanges.weight.digits,
                              true
                            )}
                          </span>
                        </span>
                      </StyledTooltip>
                    </Grid>
                  </Grid>
                </Item>
                <Item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} style={{ fontSize: "1em" }}>
                      <StyledTooltip
                        title={this.formatLastVital(
                          this.props.vitals.pulse,
                          referenceRanges.pulse.digits,
                          "only"
                        )}
                        placement="right"
                      >
                        <span>
                          <b>Heart Rate: </b>{" "}
                          <span>
                            {this.formatLastVital(
                              this.props.vitals.pulse,
                              referenceRanges.pulse.digits,
                              true
                            )}
                          </span>
                        </span>
                      </StyledTooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <ResultGraph
                        labType="pulse"
                        results={this.props.vitals.pulse}
                        latest={this.props.latest}
                        height={10}
                        daysBack={this.props.daysBack}
                      />
                    </Grid>
                  </Grid>
                </Item>
                <Item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} style={{ fontSize: "1em" }}>
                      <StyledTooltip
                        title={this.formatLastVital(
                          this.state.temp,
                          referenceRanges.temp.digits,
                          "only"
                        )}
                        placement="right"
                      >
                        <span>
                          <b>Temperature: </b>{" "}
                          <span>
                            {this.formatLastVital(
                              this.state.temp,
                              referenceRanges.temp.digits,
                              true
                            )}
                          </span>
                        </span>
                      </StyledTooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <ResultGraph
                        labType={this.state.unit === "Cel" ? "temp" : "tempF"}
                        results={this.state.temp}
                        latest={this.props.latest}
                        unitOverride={this.state.unit}
                        height={10}
                        daysBack={this.props.daysBack}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        onClick={this.buttonConvert}
                        value={
                          this.state.unit === "Cel" ? "Cel → F" : "F → Cel"
                        }
                      >
                        {this.state.unit === "Cel" ? "Cel → F" : "F → Cel"}
                      </Button>
                    </Grid>
                  </Grid>
                </Item>
                <Item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} style={{ fontSize: "1em" }}>
                      <StyledTooltip
                        title={this.formatLastVital(
                          this.props.vitals.o2,
                          referenceRanges.o2.digits,
                          "only"
                        )}
                        placement="right"
                      >
                        <span>
                          <b>Oxygen Sat: </b>{" "}
                          <span>
                            {this.formatLastVital(
                              this.props.vitals.o2,
                              referenceRanges.o2.digits,
                              true
                            )}
                          </span>
                        </span>
                      </StyledTooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <ResultGraph
                        labType="o2"
                        results={this.props.vitals.o2}
                        latest={this.props.latest}
                        height={10}
                        daysBack={this.props.daysBack}
                      />
                    </Grid>
                  </Grid>
                </Item>
                <Item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} style={{ fontSize: "1em" }}>
                      {/* <span />
                      <b>Systolic BP: </b>{" "}
                      <span>
                        {}
                      </span> */}
                      <StyledTooltip
                        title={this.formatLastVital(
                          this.props.vitals.bp,
                          referenceRanges.bp.digits,
                          "only"
                        )}
                        placement="right"
                      >
                        <span>
                          <b>Systolic BP: </b>{" "}
                          <span>
                            {this.formatLastBP(
                              "sys",
                              this.props.vitals.bp,
                              referenceRanges.sbp.digits,
                              true
                            )}
                          </span>
                        </span>
                      </StyledTooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <ResultGraph
                        labType="sbp"
                        results={this.sysFormatBP(this.props.vitals.bp)}
                        latest={this.props.latest}
                        height={10}
                        daysBack={this.props.daysBack}
                      />
                    </Grid>
                  </Grid>
                </Item>
                <Item>
                  <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={4} style={{ fontSize: "1em" }}>
                      <StyledTooltip
                        title={this.formatLastVital(
                          this.props.vitals.bp,
                          referenceRanges.bp.digits,
                          "only"
                        )}
                        placement="right"
                      >
                        <span>
                          <b>Diastolic BP: </b>{" "}
                          <span>
                            {this.formatLastBP(
                              "dia",
                              this.props.vitals.bp,
                              referenceRanges.dbp.digits,
                              true
                            )}
                          </span>
                        </span>
                      </StyledTooltip>
                    </Grid>
                    <Grid item xs={6}>
                      <ResultGraph
                        labType="dbp"
                        results={this.diaFormatBP(this.props.vitals.bp)}
                        latest={this.props.latest}
                        height={10}
                        daysBack={this.props.daysBack}
                      />
                    </Grid>
                  </Grid>
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

export default Vitals;
