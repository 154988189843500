import "./Finder.css";
import { PatientSearch } from './patient_search';
import "@coreui/coreui/dist/css/coreui.min.css"

function Finder() {
  
  return (
    <div className="Finder">
      <PatientSearch />
    </div>
  );
}

export default Finder;
