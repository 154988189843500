import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import AllergiesTable from "./C_AllergiesTable";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";

const Head = styled(Paper)(({ theme }) => ({
  backgroundColor: "#008776",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: "white",
  fontWeight: "bold",
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  marginBottom: "1px",

  textAlign: "left",
  color: "black",
}));

const bigModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "90%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

class Contact extends Component {
  state = {
    modalOpen: false,
  };

  examineNextOfKin = (patient) => {
    let text = "";
    if (patient.contact) {
      for (let i = 0; i < patient.contact.length; i++) {
        let nextOfKin = false;
        let contact = patient.contact[i];
        if (contact.relationship) {
          for (let j = 0; j < contact.relationship.length; j++) {
            if (contact.relationship[j].coding) {
              for (let k = 0; k < contact.relationship[j].coding.length; k++) {
                if (
                  contact.relationship[j].coding[k].system ===
                    "http://terminology.hl7.org/CodeSystem/v2-0131" &&
                  contact.relationship[j].coding[k].code === "N"
                )
                  nextOfKin = true;
              }
            }
          }
        }
        if (nextOfKin) {
          let telecom =
            contact.telecom && contact.telecom[0] && contact.telecom[0].value
              ? "(" + contact.telecom[0].value + ")"
              : "";
          text +=
            contact.name.given.join(" ") +
            " " +
            contact.name.family +
            " " +
            telecom;
        }
      }
    }
    return text;
  };

  showAllergies = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false, 
    });
  };

  render() {
    const showLocation =
      this.props.encounter &&
      this.props.encounter.location &&
      this.props.encounter.location[0] &&
      this.props.encounter.location[0].location &&
      this.props.encounter.location[0].location.display;
    const showPhysician =
      this.props.encounter &&
      this.props.encounter.participant &&
      this.props.encounter.participant[0] &&
      this.props.encounter.participant[0].individual &&
      this.props.encounter.participant[0].individual.display;
    const showPCP =
      this.props.Patient &&
      this.props.Patient.generalPractitioner &&
      this.props.Patient.generalPractitioner[0] &&
      this.props.Patient.generalPractitioner[0].display;

    return (
      <Grid item xs={12} md={4}>
        <Head>Patient & Contact</Head>
        <Item>
          <b>Name: </b>
          {this.props.Patient.name[0].given[0]}{" "}
          {this.props.Patient.name[0].family}
          <div></div>
          <b>DOB: </b>
          {this.props.Patient.birthDate}
        </Item>
        <Item>
          <b>Health Care Proxy: </b>
          {this.examineNextOfKin(this.props.Patient)}
        </Item>
        <Item>
          <b>Transfer Info: </b>
          {showLocation
            ? this.props.encounter.location[0].location.display + ", "
            : ""}
          {showPhysician
            ? this.props.encounter.participant[0].individual.display
            : ""}
        </Item>
        <Item>
          <b>PCP: </b>
          {showPCP ? this.props.Patient.generalPractitioner[0].display : ""}
        </Item>
        <Item>
          <b>Allergies: </b>
          <button
            className="link"
            onClick={this.showAllergies}
            style={{ display: this.props.allergies.length ? "inline" : "none" }}
          >
            Allergy detail
            <Badge badgeContent={this.props.allergies.length} color="error">
              <InfoIcon color="action" />
            </Badge>
          </button>
          <span
            style={{ display: this.props.allergies.length ? "none" : "inline" }}
          >
            No allergies recorded
          </span>
          <Modal
            open={this.state.modalOpen}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={bigModalStyle}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Allergies
                <button className="closeButton" onClick={this.handleClose}>
                  <Close />
                </button>
              </Typography>
              <AllergiesTable allergies={this.props.allergies}></AllergiesTable>
            </Box>
          </Modal>
        </Item>
      </Grid>
    );
  }
}

export default Contact;
