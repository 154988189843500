import React from "react";
import config from "../config";

export class NormalBand extends React.Component {
  static propTypes = {};

  static defaultProps = {
    style: { fill: "#C4D4CC", fillOpacity: 0.3 },
  };

  calcOffset = (stringData, referenceRange, gender, height) => {
    let data = [];
    for (let i = 0; i < stringData.y.length; i++) {
        data.push(parseFloat(stringData.y[i]))
    }

    let output = {
        y: 0, 
        height: 0
    }
    if (data.length === 0) {
        return output
    }
    else {
      if (!referenceRange.low && !referenceRange.high) {
          referenceRange = referenceRange[gender];
        }
        if (!referenceRange || (!referenceRange.low && referenceRange.low !== 0) || !referenceRange.high) {
          return output;
        }
        let heightWithMargin = height - config.sparklines.margin * 2;
        if (parseFloat(stringData.max) < referenceRange.high) { 
            output.y = config.sparklines.margin
            if (parseFloat(stringData.min) > referenceRange.low) {
                output.height = heightWithMargin;
            }
            else {
                output.height = (referenceRange.high - referenceRange.low ) / (referenceRange.high - stringData.min) * heightWithMargin
            }
        }
        else {
            if (parseFloat(stringData.min) > referenceRange.low) {
                output.y = (parseFloat(stringData.max) - referenceRange.high) / (stringData.max - referenceRange.low) * heightWithMargin + config.sparklines.margin;
                output.height = (referenceRange.high - referenceRange.low ) / (stringData.max - referenceRange.low) * heightWithMargin;
            }
            else {
                output.y = (parseFloat(stringData.max) - referenceRange.high) / (stringData.max - stringData.min) * heightWithMargin + config.sparklines.margin;
                output.height = (referenceRange.high - referenceRange.low ) / (stringData.max - stringData.min) * heightWithMargin;
            }    
        }
    }
    // console.log(referenceRange)
    // console.log(output)
    return output;
  }

  render() {
    const { originalData, referenceRange, style, gender, height } = this.props;
    return (
      <rect
        x={0}
        y={this.calcOffset(originalData, referenceRange, gender, height).y}
        width={config.sparklines.width}
        height={this.calcOffset(originalData, referenceRange, gender, height).height}
        style={style}
      />
    );
  }
}

/*

*/
