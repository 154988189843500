import moment from "moment";

export function emptyDataModel () {
  return {
    data: {
      patient: {
        name: [
          {
            given: [],
            family: "",
          },
        ],
        gender: "",
        birthDate: "",
      },
      allergies: [],
      currentEncounter: null,
      custom: [],
      dynamicDaysBackResults: 1,
      dynamicDaysBackVitals: 1,
      latestResult: moment(0).format(),
      latestVital: moment(0).format(),
      notes: [],
      otherData: {
        imaging: [],
        microbiology: [],
        procedures: [],
        radiology: [],
      },
      problems: [],
      results: {
        glucose: [],
        creatinine: [],
        ureaNitrogen: [],
        calcium: [],
        sodium: [],
        potassium: [],
        chloride: [],
        anionGap: [],
        co2: [],
        pt: [],
        ptt: [],
        inr: [],
        wbc: [],
        hemoglobin: [],
        hematocrit: [],
        platelets: [],
        ast: [],
        protein: [],
        phosphate: [],
        magnesium: [],
        alp: [],
        albumin: [],
        alt: [],
        bilirubin: [],
        bnp: [],
        ckmb: [],
        troponin: [],
        other: [],
      },
      vitals: {
        height: [],
        weight: [],
        pulse: [],
        temp: [],
        o2: [],
        bp: [],
        rr: [],
      },
      medications: [],
    },
    debugging: false,
    loadState: true,
    noDataWarning: true,
  }
}