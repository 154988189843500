import { scaleLinear } from "d3";
import React, { Component } from "react";
import moment from "moment";
import config from "../config";

export class Lft extends Component {
  chartHeight = 100;
  chartWidth = 100;
  fontSize = 16;
  x = scaleLinear().domain([0, 1]).range([0, this.chartWidth]);
  y = scaleLinear().domain([0, 1]).range([this.chartHeight, 0]);

  state = {
    ca: {},
    ast: {},
    pro: {},
    alt: {},
    alb: {},
    alp: {},
    bil: {},
  };

  assignToState = () => {
    let empty = {
      title: '', 
      value: '',
      unit: '',
      time: '',
      interpretation: 'black'
    }
    let ca, ast, pro, alt, alb, alp, bil;
    if (this.props.results.calcium && this.props.results.calcium.length) {
      ca = {
        title: 'Calcium', 
        value: this.props.results.calcium[this.props.results.calcium.length -1].value,
        unit: this.props.results.calcium[this.props.results.calcium.length -1].unit,
        time: moment(this.props.results.calcium[this.props.results.calcium.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.calcium[this.props.results.calcium.length -1].abnormal ? 'red' : 'black'
      }
    }
    else ca = empty;
    if (this.props.results.ast && this.props.results.ast.length) {
      ast = {
        title: 'AST', 
        value: this.props.results.ast[this.props.results.ast.length -1].value,
        unit: this.props.results.ast[this.props.results.ast.length -1].unit,
        time: moment(this.props.results.ast[this.props.results.ast.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.ast[this.props.results.ast.length -1].abnormal ? 'red' : 'black'
      }
    }
    else ast = empty;
    if (this.props.results.protein && this.props.results.protein.length) {
      pro = {
        title: 'Protein', 
        value: this.props.results.protein[this.props.results.protein.length -1].value,
        unit: this.props.results.protein[this.props.results.protein.length -1].unit,
        time: moment(this.props.results.protein[this.props.results.protein.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.protein[this.props.results.protein.length -1].abnormal ? 'red' : 'black'
      }
    }
    else pro = empty;
    if (this.props.results.alt && this.props.results.alt.length) {
      alt = {
        title: 'ALT', 
        value: this.props.results.alt[this.props.results.alt.length -1].value,
        unit: this.props.results.alt[this.props.results.alt.length -1].unit,
        time: moment(this.props.results.alt[this.props.results.alt.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.alt[this.props.results.alt.length -1].abnormal ? 'red' : 'black'
      }
    }
    else alt = empty;
    if (this.props.results.albumin && this.props.results.albumin.length) {
      alb = {
        title: 'Albumin', 
        value: this.props.results.albumin[this.props.results.albumin.length -1].value,
        unit: this.props.results.albumin[this.props.results.albumin.length -1].unit,
        time: moment(this.props.results.albumin[this.props.results.albumin.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.albumin[this.props.results.albumin.length -1].abnormal ? 'red' : 'black'
      }
    }
    else alb = empty
    if (this.props.results.alp && this.props.results.alp.length) {
      alp = {
        title: 'ALP', 
        value: this.props.results.alp[this.props.results.alp.length -1].value,
        unit: this.props.results.alp[this.props.results.alp.length -1].unit,
        time: moment(this.props.results.alp[this.props.results.alp.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.alp[this.props.results.alp.length -1].abnormal ? 'red' : 'black'
      }
    }
    else alp = empty;
    if (this.props.results.bilirubin && this.props.results.bilirubin.length) {
      bil = {
        title: 'Bilirubin', 
        value: this.props.results.bilirubin[this.props.results.bilirubin.length -1].value,
        unit: this.props.results.bilirubin[this.props.results.bilirubin.length -1].unit,
        time: moment(this.props.results.bilirubin[this.props.results.bilirubin.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.bilirubin[this.props.results.bilirubin.length -1].abnormal ? 'red' : 'black'
      }
    }
    else bil = empty;
    this.setState({
      ca: ca,
      ast: ast,
      pro: pro,
      alt: alt,
      alb: alb,
      alp: alp,
      bil: bil,
    })
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.results !== prevProps.results) {
      this.assignToState();
    }
  }

  //fs.writeFileSync('./yo.html', `<html>${a}</html>`);
  render() {

    return (
      <div>
        <span className="labTitles">Liver Function</span>
        <br />
        <br />
        <svg
          preserveAspectRatio="xMinYMid"
          x="50"
          y="0"
          viewBox="-20 0 120 100"
          style={{ overflow: "hidden" }}
        >
          <g stroke="grey">
            <line
              x1={this.x(0)}
              x2={this.x(1)}
              y1={this.y(0.75)}
              y2={this.y(0.75)}
            />
            <line
              x1={this.x(0)}
              x2={this.x(1)}
              y1={this.y(0.5)}
              y2={this.y(0.5)}
            />
            <line
              x1={this.x(0.5)}
              x2={this.x(0.5)}
              y1={this.y(0.3)}
              y2={this.y(1)}
            />
            <line
              x1={this.x(0.5)}
              x2={this.x(1)}
              y1={this.y(0.3)}
              y2={this.y(0)}
            />
            <line
              x1={this.x(0)}
              x2={this.x(0.5)}
              y1={this.y(0)}
              y2={this.y(0.3)}
            />
          </g>
          <g fontSize={this.fontSize}>
            <text
              x={this.x(0.25)}
              y={this.y(0.88)}
              fill={this.state.ca.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.ca.value}
              <title>
                {this.state.ca.title}
                {"\n"}
                {this.state.ca.unit}
                {"\n"}
                {this.state.ca.time}
              </title>
            </text>
            <text
              x={this.x(0.75)}
              y={this.y(0.88)}
              fill={this.state.ast.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.ast.value}
              <title>
                {this.state.ast.title}
                {"\n"}
                {this.state.ast.unit}
                {"\n"}
                {this.state.ast.time}
              </title>
            </text>
            <text
              x={this.x(0.25)}
              y={this.y(0.63)}
              fill={this.state.pro.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.pro.value}
              <title>
                {this.state.pro.title}
                {"\n"}
                {this.state.pro.unit}
                {"\n"}
                {this.state.pro.time}
              </title>
            </text>
            <text
              x={this.x(0.75)}
              y={this.y(0.63)}
              fill={this.state.alt.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.alt.value}
              <title>
                {this.state.alt.title}
                {"\n"}
                {this.state.alt.unit}
                {"\n"}
                {this.state.alt.time}
              </title>
            </text>
            <text
              x={this.x(0.25)}
              y={this.y(0.4)}
              fill={this.state.alb.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.alb.value}
              <title>
                {this.state.alb.title}
                {"\n"}
                {this.state.alb.unit}
                {"\n"}
                {this.state.alb.time}
              </title>
            </text>
            <text
              x={this.x(0.75)}
              y={this.y(0.4)}
              fill={this.state.alp.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.alp.value}
              <title>
                {this.state.alp.title}
                {"\n"}
                {this.state.alp.unit}
                {"\n"}
                {this.state.alp.time}
              </title>
            </text>
            <text
              x={this.x(0.5)}
              y={this.y(0.1)}
              fill={this.state.bil.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.bil.value}
              <title>
                {this.state.bil.title}
                {"\n"}
                {this.state.bil.unit}
                {"\n"}
                {this.state.bil.time}
              </title>
            </text>
          </g>
        </svg>
      </div>
    );
  }
}