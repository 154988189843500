import { resultMap, vitalMap, earliest, latest } from "./lab_helper";
import { get } from "lodash";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { referenceRanges } from "./referenceRanges";
import config from "../config";

const mapResult = function (FHIR, gender) {
  let mapping = "other";
  let lab = false;
  let newResult = {
    id: uuidv4(),
    LOINC: "",
    text: "No Text Available",
    effectiveDateTime: "",
    interpretation: "",
    value: "",
    valueString: null,
    unit: "",
    component: null,
    abnormal: null,
  };
  for (let j = 0; j < get(FHIR, `code.coding.length`, 0); j++) {
    if (get(FHIR, `code.coding[${j}].system`) === "http://loinc.org") {
      if (get(FHIR, `code.coding[${j}].code`)) {
        if (resultMap[get(FHIR, `code.coding[${j}].code`)]) {
          mapping = resultMap[get(FHIR, `code.coding[${j}].code`)];
          lab = true;
        } else if (vitalMap[get(FHIR, `code.coding[${j}].code`)]) {
          mapping = vitalMap[get(FHIR, `code.coding[${j}].code`)];
        }
        newResult.LOINC = get(FHIR, `code.coding[${j}].code`);
        if (get(FHIR, `code.text`)) {
          newResult.text = get(FHIR, `code.text`);
        } else if (get(FHIR, `code.coding[${j}].display`)) {
          newResult.text = get(FHIR, `code.text`);
        }
      }
    }
  }
  newResult.effectiveDateTime = get(FHIR, `effectiveDateTime`, "Time Missing");
  newResult.value = get(FHIR, `valueQuantity.value`, "Value Missing");
  newResult.unit = get(FHIR, `valueQuantity.unit`, "Unit Missing");
  if (newResult.value === "Value Missing" && FHIR.valueString) {
    newResult.valueString = FHIR.valueString;
    newResult.unit = "";
  }
  newResult.interpretation = get(
    FHIR,
    `interpretation[0].coding[0].code`,
    "No Interpretation"
  );
  newResult.component = get(FHIR, `component`, null);
  if (lab && mapping !== "other") {
    if (
      referenceRanges[mapping].high &&
      (referenceRanges[mapping].low || referenceRanges[mapping].low === 0)
    ) {
      if (
        newResult.value < referenceRanges[mapping].low ||
        newResult.value > referenceRanges[mapping].high
      ) {
        newResult.abnormal = true;
      }
    } else {
      if (
        newResult.value < referenceRanges[mapping][gender].low ||
        newResult.value > referenceRanges[mapping][gender].high
      ) {
        newResult.abnormal = true;
      }
    }
  }
  return {
    mapping: mapping,
    newResult: newResult,
  };
};

const noteLatest = function (a, b) {
  if (!b.date) {
    return -1;
  } else if (a.date < b.date) {
    return 1;
  } else if (a.date > b.date) {
    return -1;
  } else {
    return 0;
  }
};

export function FHIR_to_model(bundle) {
  let model = {
    patient: {
      name: [
        {
          given: [],
          family: "",
        },
      ],
      birthDate: "",
      gender: "",
    },
    allergies: [],
    custom: [],
    currentEncounter: null,
    dynamicDaysBackResults: 1,
    dynamicDaysBackVitals: 1,
    medications: [],
    latestResult: moment(0).format(),
    latestVital: moment(0).format(),
    notes: [],
    otherData: {
      microbiology: [],
      imaging: [],
      procedures: [],
      radiology: [],
    },
    problems: [],
    results: {
      glucose: [],
      creatinine: [],
      ureaNitrogen: [],
      calcium: [],
      sodium: [],
      potassium: [],
      chloride: [],
      anionGap: [],
      co2: [],
      pt: [],
      ptt: [],
      inr: [],
      wbc: [],
      hemoglobin: [],
      hematocrit: [],
      platelets: [],
      ast: [],
      protein: [],
      phosphate: [],
      magnesium: [],
      alp: [],
      albumin: [],
      alt: [],
      bilirubin: [],
      bnp: [],
      ckmb: [],
      troponin: [],
      other: [],
    },
    vitals: {
      height: [],
      weight: [],
      pulse: [],
      temp: [],
      o2: [],
      bp: [],
      rr: []
    },
  };
  let resultTimes = [];
  let vitalTimes = [];
  if (!bundle || !bundle.entry) {
    return model;
  } else {
    // need to do patient first, since need gender
    let gender = "female";
    for (let i = 0; i < bundle.entry.length; i++) {
      if (bundle.entry[i].resource.resourceType === "Patient") {
        model.patient = bundle.entry[i].resource;
        if (model.patient.gender === "male") gender = "male";
      }
    }
    // loop for all other resources
    for (let i = 0; i < bundle.entry.length; i++) {
      if (bundle.entry[i].resource && bundle.entry[i].resource.resourceType) {
        if (bundle.entry[i].resource.resourceType === "Observation") {
          // need to add more sorting for other types
          let newResult = mapResult(bundle.entry[i].resource, gender);
          // Handle custom observations just for app (Reason and action list)
          if (
            bundle.entry[i].resource.code &&
            bundle.entry[i].resource.code.text &&
            bundle.entry[i].resource.code.text.slice(0, 9) === "IHTCustom"
          ) {
            model.custom.push(bundle.entry[i].resource);
          }
          // console.log(newResult);
          else if (model.vitals[newResult.mapping]) {
            model.vitals[newResult.mapping].push(newResult.newResult);
            if (newResult.newResult.effectiveDateTime) {
              vitalTimes.push(newResult.newResult.effectiveDateTime);
              if (
                moment(newResult.newResult.effectiveDateTime).isAfter(
                  moment(model.latestVital)
                )
              ) {
                model.latestVital = moment(
                  newResult.newResult.effectiveDateTime
                ).format();
              }
            }
          } else {
            if (newResult.newResult.effectiveDateTime) {
              resultTimes.push(newResult.newResult.effectiveDateTime);
              if (
                moment(newResult.newResult.effectiveDateTime).isAfter(
                  moment(model.latestResult)
                )
              ) {
                model.latestResult = moment(
                  newResult.newResult.effectiveDateTime
                ).format();
              }
            }
            model.results[newResult.mapping].push(newResult.newResult);
            // double push these labs since they are on the fishbones but not graphs, so also need in other
            if (
              newResult.mapping === "magnesium" ||
              newResult.mapping === "phosphate"
            ) {
              model.results.other.push(newResult.newResult);
            }
          }
        }
        if (bundle.entry[i].resource.resourceType === "AllergyIntolerance")
          model.allergies.push(bundle.entry[i].resource);
        if (bundle.entry[i].resource.resourceType === "MedicationRequest")
          model.medications.push(bundle.entry[i].resource);
        if (bundle.entry[i].resource.resourceType === "Condition")
          model.problems.push(bundle.entry[i].resource);
        if (bundle.entry[i].resource.resourceType === "Procedure")
          model.otherData.procedures.push(bundle.entry[i].resource);
        if (bundle.entry[i].resource.resourceType === "Media")
          model.otherData.imaging.push(bundle.entry[i].resource);
        if (bundle.entry[i].resource.resourceType === "DocumentReference")
          model.notes.push(bundle.entry[i].resource);
        if (bundle.entry[i].resource.resourceType === "DiagnosticReport")
          model.otherData.radiology.push(bundle.entry[i].resource);
        if (
          bundle.entry[i].resource.resourceType === "Encounter" &&
          bundle.entry[i].resource.status === "in-progress"
        )
          model.currentEncounter = bundle.entry[i].resource;
      }
    }
  }

  resultTimes.sort();
  // console.log(resultTimes);
  for (let i = 0; i < resultTimes.length; i++) {
    if (
      moment(resultTimes[i]).isAfter(
        moment(model.latestResult).subtract(config.sparklines.daysBack, "day")
      )
    ) {
      if (
        moment(model.latestResult).diff(moment(resultTimes[i]), "day") + 1>
          model.dynamicDaysBackResults &&
        moment(model.latestResult).diff(moment(resultTimes[i]), "day") <
          config.sparklines.daysBack
      ) {
        model.dynamicDaysBackResults = Math.max(
          model.dynamicDaysBackResults,
          Math.min(
            7,
            moment(model.latestResult).diff(moment(resultTimes[i]), "day") + 1
          )
        );
      }
    }
  }
  vitalTimes.sort();
  for (let i = 0; i < vitalTimes.length; i++) {
    if (
      moment(vitalTimes[i]).isAfter(
        moment(model.latestVital).subtract(config.sparklines.daysBack, "day")
      )
    ) {
      if (
        moment(model.latestVital).diff(moment(vitalTimes[i]), "day") + 1 >
          model.dynamicDaysBackVitals &&
        moment(model.latestVital).diff(moment(vitalTimes[i]), "day") + 1 <
          config.sparklines.daysBack
      ) {
        model.dynamicDaysBackVitals = Math.max(
          model.dynamicDaysBackVitals,
          Math.min(
            7,
            moment(model.latestVital).diff(moment(vitalTimes[i]), "day") + 1
          )
        );
      }
    }
  }

  // For indexed results, order by date chronologically. For other results, order by date reverse chronologically
  for (let key in model.results) {
    if (model.results.hasOwnProperty(key)) {
      if (key === "other") model.results[key].sort(latest);
      else model.results[key].sort(earliest);
    }
  }

  // For vital signs, order by date chronologically
  for (let key in model.vitals) {
    if (model.vitals.hasOwnProperty(key)) {
      model.vitals[key].sort(earliest);
    }
  }

  model.notes.sort(noteLatest);

  return model;
}
