import { Component } from "react";

class ShowMedia extends Component {
  render() {
    const showData =
      this.props.report &&
      this.props.report.content &&
      this.props.report.content.data;
    const ImageData = showData ? `data:image/jpeg;base64,${this.props.report.content.data}` : 'data:image/jpeg;base64,';
    return (
      <div>
        <b>
        {this.props.report.id
          ? this.props.report.id
          : "No Identifier"}
          &nbsp;
        {this.props.report.createdDateTime
          ? this.props.report.createdDateTime
          : "No Date Time"}
        </b>
        <div>
          <img src={ImageData} alt="FHIR Resource Media" width="100%" />
        </div>
      </div>
    );
  }
}

export default ShowMedia;