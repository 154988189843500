let referenceRanges = {
  //basic metabolic panel
  chloride: { low: 95, high: 106, unit: "mmol/L", digits: 0 },
  co2: { low: 23, high: 29, unit: "mmol/L", digits: 0 },
  creatinine: {
    female: {
      low: 0.6,
      high: 1.1,
    },
    male: {
      low: 0.7,
      high: 1.3,
    },
    unit: "mg/dL",
    digits: 1,
  },
  glucose: { low: 60, high: 125, unit: "mg/dL", digits: 0 },
  sodium: { low: 135, high: 145, unit: "mmol/L", digits: 0 },
  potassium: { low: 3.7, high: 5.2, unit: "mmol/L", digits: 1 },
  ureaNitrogen: { low: 7, high: 20, unit: "mg/dL", digits: 0 },
  // coagulation
  pt: { low: 9.1, high: 12, unit: "s", digits: 0 },
  ptt: { low: 25, high: 35, unit: "s", digits: 0 },
  inr: { low: 0.8, high: 1.1, digits: 1 },
  // complete blood count
  hematocrit: { low: 36.1, high: 50.3, unit: "%", digits: 1 },
  hemoglobin: {
    female: {
      low: 11.6,
      high: 15,
    },
    male: {
      low: 13.2,
      high: 16.6,
    },
    unit: "g/dL",
    digits: 1,
  },
  platelets: { low: 150, high: 450, unit: "10*3/uL", digits: 0 },
  wbc: { low: 3.5, high: 10.5, unit: "10*3/uL", digits: 1 },
  // liver function
  albumin: { low: 3.4, high: 5.4, unit: "g/dL", digits: 1 },
  alp: { low: 44, high: 147, unit: "U/L", digits: 0 },
  alt: { low: 4, high: 40, unit: "U/L", digits: 0 },
  ast: { low: 10, high: 34, unit: "U/L", digits: 0 },
  calcium: { low: 8.5, high: 10.2, unit: "mg/dL", digits: 1 },
  protein: { low: 6.4, high: 8.3, unit: "g/dL", digits: 1 },
  bilirubin: { low: 0.1, high: 1.2, unit: "mg/dL", digits: 1 },
  // Mg and Phosphate
  magnesium: { low: 1.7, high: 2.2, unit: "mg/dL", digits: 1},
  phosphate: { low: 2.4, high: 4.1, unit: "mg/dL", digits: 1},
  // cardiac panel
  bnp: { low: 0, high: 100, unit: "pg/mL", digits: 0 },
  ckmb: {
    female: {
      low: 0,
      high: 4.4,
    },
    male: {
      low: 0,
      high: 7.8,
    },
    unit: "ng/mL",
    digits: 1,
  },
  // This is high senstivity troponin
  troponin: {
    low: 0,
    high: 14, 
    unit: 'ng/L',
    digits: 0
  },
  // vitals signs
  height: { unit: "cm", digits: 0 },
  weight: { unit: "kg", digits: 0 },
  pulse: { low: 60, high: 100, unit: "/min", digits: 0 },
  temp: { low: 36.1, high: 37.2, unit: "Cel", digits: 1 },
  tempF: { low: 97, high: 99, unit: "F", digits: 1 },
  o2: { low: 95, high: 100, unit: "%", digits: 0 },
  bp: { unit: "mmHg", digits: 0 },
  sbp: { low: 80, high: 120, unit: "mmHg", digits: 0 },
  dbp: { low: 40, high: 80, unit: "mmHg", digits: 0 },
};

export { referenceRanges };
