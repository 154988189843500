// const fs = require('fs');
import { scaleLinear } from 'd3';
import React, { Component } from 'react';
import moment from "moment";
import config from "../config";

export class Coag extends Component {
  fontSize = 16;
  chartHeight = 90;
  chartWidth = 90;
  x = scaleLinear().domain([0, 1]).range([0, this.chartWidth]);
  y = scaleLinear().domain([0, 1]).range([this.chartHeight, 0]);

  state = {
    pt: { },
    ptt: { },
    inr: { }  
  };

  assignToState = () => {
    let empty = {
      title: '', 
      value: '',
      unit: '',
      time: '',
      interpretation: 'black'
    }
    let pt, ptt, inr;
    if (this.props.results.pt && this.props.results.pt.length) {
      pt = {
        title: 'Prothrombin Time', 
        value: this.props.results.pt[this.props.results.pt.length -1].value,
        unit: this.props.results.pt[this.props.results.pt.length -1].unit,
        time: moment(this.props.results.pt[this.props.results.pt.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.pt[this.props.results.pt.length -1].abnormal ? 'red' : 'black'
      }
    }
    else pt = empty;
    if (this.props.results.ptt && this.props.results.ptt.length) {
      ptt = {
        title: 'Partial Thromboplastin Time', 
        value: this.props.results.ptt[this.props.results.ptt.length -1].value,
        unit: this.props.results.ptt[this.props.results.ptt.length -1].unit,
        time: moment(this.props.results.ptt[this.props.results.ptt.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.ptt[this.props.results.ptt.length -1].abnormal ? 'red' : 'black'
      }
    }
    else ptt = empty;
    if (this.props.results.inr && this.props.results.inr.length) {
      inr = {
        title: 'International Normalized Ratio', 
        value: this.props.results.inr[this.props.results.inr.length -1].value,
        unit: this.props.results.inr[this.props.results.inr.length -1].unit,
        time: moment(this.props.results.inr[this.props.results.inr.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.inr[this.props.results.inr.length -1].abnormal ? 'red' : 'black'
      }
    }
    else inr = empty;
    this.setState({
      pt: pt,
      ptt: ptt,
      inr: inr
    })
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.results !== prevProps.results) {
      this.assignToState();
    }
  }

  render() {
    
    return(
    <div>
      <span className="labTitles" >Coagulation</span>
      <svg preserveAspectRatio="xMinYMid" x="0" y = "0" viewBox="0 -10 100 100" style={{overflow:"hidden"}}>
      <g stroke="grey">
        <line x1={this.x(0.05)} x2={this.x(0.5)} y1={this.y(0.2)} y2={this.y(0.45)} />
        <line x1={this.x(0.5)} x2={this.x(0.95)} y1={this.y(0.45)} y2={this.y(0.2)} />
        <line x1={this.x(0.5)} x2={this.x(0.5)} y1={this.y(0.45)} y2={this.y(1)} />
      </g>
      <g fontSize={this.fontSize} >
        <text x={this.x(.25)} y={this.y(.75)} fill={this.state.pt.interpretation} textAnchor="middle" alignmentBaseline="central">{this.state.pt.value}
        <title>
                {this.state.pt.title}{'\n'}
                {this.state.pt.unit}{'\n'}
                {this.state.pt.time}
          </title>
        </text>
        <text x={this.x((.75))} y={this.y(.75 )} fill={this.state.ptt.interpretation} textAnchor="middle" alignmentBaseline="central" >{this.state.ptt.value}
          <title>
                {this.state.ptt.title}{'\n'}
                {this.state.ptt.unit}{'\n'}
                {this.state.ptt.time}
          </title>
        </text>
        <text x={this.x((.5))} y={this.y(.25)} fill={this.state.inr.interpretation} textAnchor="middle" alignmentBaseline="central" >{this.state.inr.value}
          <title>
                {this.state.inr.title}{'\n'}
                {this.state.inr.unit}{'\n'}
                {this.state.inr.time}
          </title>
        </text>
      </g>
      </svg>
    </div>  
    )
  }
}