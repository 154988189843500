import React, { Component } from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

const Head = styled(Paper)(({ theme }) => ({
    backgroundColor: '#008776',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: 'white',
    fontWeight: 'bold'
}));

class Links extends Component {
    render() {
        return (
            <Grid item xs={12} md={12}>
                <Head>Links</Head>

            </Grid>
        )
    }
}

export default Links;