import { scaleLinear } from "d3";
import React, { Component } from "react";
import moment from "moment";
import config from "../config";

export class Cbc extends Component {
  fontSize = 16;
  chartHeight = 40;
  chartWidth = 100;
  x = scaleLinear().domain([0, 1]).range([0, this.chartWidth]);
  y = scaleLinear().domain([0, 1]).range([this.chartHeight, 0]);

  state = {
    wbc: {},
    hemoglobin: {},
    hematocrit: {},
    platelets: {},
  };

  assignToState = () => {
    let empty = {
      title: "",
      value: "",
      unit: "",
      time: "",
      interpretation: "black",
    };
    let wbc, hemoglobin, hematocrit, platelets;
    if (this.props.results.wbc && this.props.results.wbc.length) {
      wbc = {
        title: "WBC (Leukocytes)",
        value: this.props.results.wbc[this.props.results.wbc.length - 1].value,
        unit: this.props.results.wbc[this.props.results.wbc.length - 1].unit,
        time: moment(
          this.props.results.wbc[this.props.results.wbc.length - 1]
            .effectiveDateTime
        ).format(config.dateFormat),
        interpretation: this.props.results.wbc[
          this.props.results.wbc.length - 1
        ].abnormal
          ? "red"
          : "black",
      };
    } else wbc = empty;
    if (this.props.results.hemoglobin && this.props.results.hemoglobin.length) {
      hemoglobin = {
        title: "Hemoglobin",
        value:
          this.props.results.hemoglobin[
            this.props.results.hemoglobin.length - 1
          ].value,
        unit: this.props.results.hemoglobin[
          this.props.results.hemoglobin.length - 1
        ].unit,
        time: moment(
          this.props.results.hemoglobin[
            this.props.results.hemoglobin.length - 1
          ].effectiveDateTime
        ).format(config.dateFormat),
        interpretation: this.props.results.hemoglobin[
          this.props.results.hemoglobin.length - 1
        ].abnormal
          ? "red"
          : "black",
      };
    } else hemoglobin = empty;
    if (this.props.results.hematocrit && this.props.results.hematocrit.length) {
      hematocrit = {
        title: "Hematocrit",
        value:
          this.props.results.hematocrit[
            this.props.results.hematocrit.length - 1
          ].value,
        unit: this.props.results.hematocrit[
          this.props.results.hematocrit.length - 1
        ].unit,
        time: moment(
          this.props.results.hematocrit[
            this.props.results.hematocrit.length - 1
          ].effectiveDateTime
        ).format(config.dateFormat),
        interpretation: this.props.results.hematocrit[
          this.props.results.hematocrit.length - 1
        ].abnormal
          ? "red"
          : "black",
      };
    } else hematocrit = empty;
    if (this.props.results.platelets && this.props.results.platelets.length) {
      platelets = {
        title: "platelets",
        value:
          this.props.results.platelets[this.props.results.platelets.length - 1]
            .value,
        unit: this.props.results.platelets[
          this.props.results.platelets.length - 1
        ].unit,
        time: moment(
          this.props.results.platelets[this.props.results.platelets.length - 1]
            .effectiveDateTime
        ).format(config.dateFormat),
        interpretation: this.props.results.platelets[
          this.props.results.platelets.length - 1
        ].abnormal
          ? "red"
          : "black",
      };
    } else platelets = empty;
    this.setState({
      wbc: wbc,
      hemoglobin: hemoglobin,
      hematocrit: hematocrit,
      platelets: platelets,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.results !== prevProps.results) {
      this.assignToState();
    }
  };

  render() {
    // console.log(this.props.parentToChild)

    this.xDist = 0.05;
    this.centerOffset = 0.33;

    return (
      <div>
        <span className="labTitles">Complete Blood Count</span>
        <br />
        <br />
        <svg
          preserveAspectRatio="xMinYMid"
          x="0"
          y="0"
          viewBox="0 -10 100 50"
          style={{ overflow: "hidden" }}
        >
          <g stroke="grey">
            <line
              x1={this.x(this.centerOffset - this.xDist)}
              x2={this.x(this.centerOffset)}
              y1={this.y(1)}
              y2={this.y(0.5)}
            />
            <line
              x1={this.x(this.centerOffset - this.xDist)}
              x2={this.x(this.centerOffset)}
              y1={this.y(0)}
              y2={this.y(0.5)}
            />
            <line
              x1={this.x(this.centerOffset)}
              x2={this.x(1 - this.centerOffset)}
              y1={this.y(0.5)}
              y2={this.y(0.5)}
            />
            <line
              x1={this.x(1 - this.centerOffset)}
              x2={this.x(1 - this.centerOffset + this.xDist)}
              y1={this.y(0.5)}
              y2={this.y(1)}
            />
            <line
              x1={this.x(1 - this.centerOffset)}
              x2={this.x(1 - this.centerOffset + this.xDist)}
              y1={this.y(0.5)}
              y2={this.y(0)}
            />
          </g>
          <g fontSize={this.fontSize}>
            <text
              x={this.x(0)}
              y={this.y(0.5)}
              fill={this.state.wbc.interpretation}
              textAnchor="start"
              alignmentBaseline="central"
            >
              {this.state.wbc.value}
              <title>
                {this.state.wbc.title}
                {"\n"}
                {this.state.wbc.unit}
                {"\n"}
                {this.state.wbc.time}
              </title>
            </text>
            <text
              x={this.x(0.5)}
              y={this.y(0.75)}
              fill={this.state.hemoglobin.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.hemoglobin.value}
              <title>
                {this.state.hemoglobin.title}
                {"\n"}
                {this.state.hemoglobin.unit}
                {"\n"}
                {this.state.hemoglobin.time}
              </title>
            </text>
            <text
              x={this.x(0.5)}
              y={this.y(0.25)}
              fill={this.state.hematocrit.interpretation}
              textAnchor="middle"
              alignmentBaseline="central"
            >
              {this.state.hematocrit.value}
              <title>
                {this.state.hematocrit.title}
                {"\n"}
                {this.state.hematocrit.unit}
                {"\n"}
                {this.state.hematocrit.time}
              </title>
            </text>
            <text
              x={this.x(1)}
              y={this.y(0.5)}
              fill={this.state.platelets.interpretation}
              textAnchor="end"
              alignmentBaseline="central"
            >
              {this.state.platelets.value}
              <title>
                {this.state.platelets.title}
                {"\n"}
                {this.state.platelets.unit}
                {"\n"}
                {this.state.platelets.time}
              </title>
            </text>
          </g>
        </svg>
      </div>
    );
  }
}
