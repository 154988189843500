import { Component } from "react";
import Grid from "@mui/material/Grid";
import { Bmp } from "./skeletons/lab_bmp";
import { Cbc } from "./skeletons/lab_cbc";
import { Lft } from "./skeletons/lab_lft";
import { Camgp } from "./skeletons/lab_ca-mg-p";
import { Coag } from "./skeletons/lab_coag";

class Skeleton extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={10}>
            <Bmp results={this.props.results}></Bmp>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            <Coag results={this.props.results}></Coag>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={5}>
            <Cbc results={this.props.results}></Cbc>
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            <Camgp results={this.props.results} />
          </Grid>
          <Grid item xs={1}>
            &nbsp;
          </Grid>
          <Grid item xs={4}>
            <Lft results={this.props.results}></Lft>
          </Grid>
          <Grid item xs={2}>
            &nbsp;
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Skeleton;


/*


*/