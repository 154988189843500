import { get } from "lodash";

// Assigns results based on LOINC code to a property to be used in React component
// Multiple LOINC codes may be assigned to same property, latest will show
let resultMap = {
  "2345-7": "glucose",
  "2339-0": "glucose",
  "2160-0": "creatinine",
  "38483-4": "creatinine",
  "6299-2": "ureaNitrogen",
  "3094-0": "ureaNitrogen",
  "17861-6": "calcium",
  "49765-1": "calcium",
  "2951-2": "sodium",
  "2947-0": "sodium",
  "2823-3": "potassium",
  "6298-4": "potassium",
  "2075-0": "chloride",
  "2069-3": "chloride",
  //'33037-3': 'anionGap',
  "2028-9": "co2",
  "20565-8": "co2",
  "5902-2": "pt",
  "3173-2": "ptt",
  "34714-6": "inr",
  "26464-8": "wbc",
  // "789-8": "rbc",
  "718-7": "hemoglobin",
  "4544-3": "hematocrit",
  "777-3": "platelets",
  "1920-8": "ast",
  "2885-2": "protein",
  "2777-1": "phosphate",
  "19123-9": "magnesium",
  "6768-6": "alp",
  "1751-7": "albumin",
  "1742-6": "alt",
  "1975-2": "bilirubin",
  "30934-4": "bnp",
  //  '10839-9': 'troponin', only doing high senstivity
  "67151-1": "troponin",
  "13969-1": "ckmb",
};

let vitalMap = {
  "8302-2": "height",
  "29463-7": "weight",
  "8867-4": "pulse",
  "8310-5": "temp",
  "2708-6": "o2",
  "85354-9": "bp",
  "9279-1": "rr"
};

// results are assumed to be FHIR R4 Observation
const labHelper = function (assignment, results) {
  results.sort(latest);
  let object = { results: results };
  for (let i = 0; i < results.length; i++) {
    let getLOINC = null;
    let title = null;
    for (
      let j = 0;
      j < get(object, `results[${i}].code.coding.length`, 0);
      j++
    ) {
      if (
        get(object, `results[${i}].code.coding[${j}].system`) ===
          "http://loinc.org" &&
        resultMap[get(object, `results[${i}].code.coding[${j}].code`)]
      ) {
        getLOINC = resultMap[results[i].code.coding[j].code];
        if (get(object, `results[${i}].code.text`)) {
          title = results[i].code.text;
        }
        if (get(object, `results[${i}].code.coding[${j}].display`)) {
          title = results[i].code.coding[j].display;
        } else {
          title = `${getLOINC} [source display missing]`;
        }
        break;
      }
    }
    // only assign if not already populated since reverse chrono sorted
    if (getLOINC && assignment[getLOINC] && !assignment[getLOINC].populated) {
      assignment[getLOINC].populated = true;
      assignment[getLOINC].title = title;
      assignment[getLOINC].time = get(
        object,
        `results[${i}].effectiveDateTime`,
        "Time Missing"
      );
      assignment[getLOINC].value = get(
        object,
        `results[${i}].valueQuantity.value`,
        " "
      );
      if (assignment[getLOINC].value)
        assignment[getLOINC].value =
          Math.round(assignment[getLOINC].value * 10) / 10;
      assignment[getLOINC].unit = get(
        object,
        `results[${i}].valueQuantity.unit`,
        " "
      );
      // Note that no interpretation will be red for time being
      if (
        get(object, `results[${i}].interpretation[0].coding[0].code`) !== "N"
      ) {
        assignment[getLOINC].interpretation = "red";
      } else {
        assignment[getLOINC].interpretation = "black";
      }
    }
  }
  return assignment;
};

// moves any labs without a date to the end. Latest results come first
const latest = function (a, b) {
  if (!b.effectiveDateTime) {
    return -1;
  } else if (a.effectiveDateTime < b.effectiveDateTime) {
    return 1;
  } else if (a.effectiveDateTime > b.effectiveDateTime) {
    return -1;
  } else {
    return 0;
  }
};

const earliest = function (a, b) {
  if (!b.effectiveDateTime) {
    return 1;
  } else if (a.effectiveDateTime < b.effectiveDateTime) {
    return -1;
  } else if (a.effectiveDateTime > b.effectiveDateTime) {
    return 1;
  } else {
    return 0;
  }
};

export { labHelper, resultMap, vitalMap, latest, earliest };
