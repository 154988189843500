export function IHTObservation(snapshotState, Patient) {
  let valueString = snapshotState.illnessSeverity + '|' + snapshotState.reason + '|' +  snapshotState.primaryComplaint + '|' + snapshotState.actionDisplay

  return {
    resourceType: 'Observation',
    status: 'preliminary', 
    code: {
      text: 'IHTCustom'
    },
    subject: {
      reference: `Patient/${Patient.id}`
    },
    valueString: valueString
  };
}
