const config = {
    dateFormat: "M/D/YYYY H:mma",
    server: 'https://hapi.stage.interhospital.app/fhir/',
    sparklines: {
      width: 100, 
      height: 14, 
      margin: 2, 
      daysBack: 7
    }
};

module.exports = config;