import { useRef, useState } from "react";
import "./styles.css"; // required styles
import "./tabulator_bootstrap.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Close from "@mui/icons-material/Close";
import moment from 'moment';
import config from './config';

/*
function updateFilter() {
  let filterVal = fieldEl.options[fieldEl.selectedIndex].value;
  let typeVal = typeEl.options[typeEl.selectedIndex].value;

  let filter = filterVal == "function" ? customFilter : filterVal;

  if (filterVal == "function") {
    typeEl.disabled = true;
    valueEl.disabled = true;
  } else {
    typeEl.disabled = false;
    valueEl.disabled = false;
  }

  if (filterVal) {
    table.setFilter(filter, typeVal, valueEl.value);
  }
}

*/

const bigModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "65%",
  maxHeight: "90%",
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

let columns = [
  { title: "Note Type", field: "type", width: 200 },
  { title: "Date", field: "date", width: 200 },
  {
    title: "Note Content",
    field: "content",
    minWidth: 800,
    formatter: "textarea",
  },
];



export default function NoteSearch({ notes }) {
  // console.log(notes)
  let ref = useRef(null);
  let [state, setState] = useState({
    modal: false,
    content: "",
    highlight: ""
  });

  const handleClose = () => {
    setState({
      modal: false,
      content: "",
    });
  };

  const rowClickHandler = (e, row) => {
    setState({
      modal: true,
      content: row._row.data.content,
    });
  };

  const updateFilter = function (e, ref) {
    //alert('yo2');
    // console.log(ref);
    // console.log(e.target.value);
    ref.current.setFilter("content", "like", e.target.value);
    /*
    setState({
      highlight: e.target.value,
      content: state.content
    })
    */
  };

  let data = [];
  for (let i = 0; i < notes.length; i++) {
    let item = {
      type: notes[i].type.text,
      date: moment(notes[i].date).format(config.dateFormat),
      content: atob(notes[i].content[0].attachment.data),
    };
    data.push(item);
  }
  return (
    <div>
      <input
        id="filter-value"
        type="text"
        className="form-control"
        style={{ display: "inline-block", fontSize: "1.5rem" }}
        placeholder="Search Notes"
        onChange={function (e) {
          updateFilter(e, ref);
        }}
      />
      <br />
      <br />
      <ReactTabulator
        onRef={(r) => (ref = r)}
        columns={columns}
        data={data}
        events={{
          rowClick: function (e, row) {
            rowClickHandler(e, row);
          },
        }}
      />
      <Modal
        id="searchModalNote"
        open={state.modal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={bigModalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <b>Highlighted Note</b>
            <button className="closeButton" onClick={handleClose}>
              <Close />
            </button>
            <div>
              {state.content} 
            </div>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
