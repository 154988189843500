const abnormalCount = function (results) {
  let data = {
    bmp: {
      denom: 0, 
      numer: 0
    },
    cbc: {
      denom: 0, 
      numer: 0
    },
    coag: {
      denom: 0, 
      numer: 0
    },
    liver: {
      denom: 0, 
      numer: 0
    },
    cardiac: {
      denom: 0, 
      numer: 0
    }
  }
  for (let key in results) {
    if (results.hasOwnProperty(key)) {
      if (['chloride','co2','creatinine','glucose','sodium','potassium','ureaNitrogen'].includes(key)){
        if (results[key].length) {
          data.bmp.denom++;
          if (results[key][results[key].length - 1].abnormal) {
            data.bmp.numer++;
          }
        }
      } 
      else if (['pt','ptt','inr'].includes(key)){
        if (results[key].length) {
          data.coag.denom++;
          if (results[key][results[key].length - 1].abnormal) {
            data.coag.numer++;
          }
        }
      } 
      else if (['hematocrit','hemoglobin','platelets','wbc'].includes(key)){
        if (results[key].length) {
          data.cbc.denom++;
          if (results[key][results[key].length - 1].abnormal) {
            data.cbc.numer++;
          }
        }
      } 
      else if (['albumin','alp','alt','ast','calcium','protein','bilirubin'].includes(key)){
        if (results[key].length) {
          data.liver.denom++;
          if (results[key][results[key].length - 1].abnormal) {
            data.liver.numer++;
          }
        }
      } 
      else if (['bnp','ckmb','troponin'].includes(key)){
        if (results[key].length) {
          data.cardiac.denom++;
          if (results[key][results[key].length - 1].abnormal) {
            data.cardiac.numer++;
          }
        }
      } 
    }
  }
  return data;
}

export { abnormalCount };