import { Component } from "react";

class ShowDiagnosticReport extends Component {
  render() {
    // console.log(this.props.report);
    const showData =
      this.props.report &&
      this.props.report.presentedForm &&
      this.props.report.presentedForm[0];
    return (
      <div>
        <b>
        {this.props.report.code
          ? this.props.report.code.text
          : "No Description"}
          &nbsp;
        {this.props.report.effectiveDateTime
          ? this.props.report.effectiveDateTime
          : "No Date Time"}
        </b>
        <div style={{ whiteSpace: "pre-wrap" }}>
          {showData
            ? atob(this.props.report.presentedForm[0].data)
            : "no attached data"}
        </div>
      </div>
    );
  }
}

export default ShowDiagnosticReport;
