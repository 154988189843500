// const fs = require('fs');
import { scaleLinear } from 'd3';
import React, { Component } from 'react';
import moment from "moment";
import config from "../config";

export class Camgp extends Component {
  fontSize = 16;
  chartHeight = 90;
  chartWidth = 90;
  x = scaleLinear().domain([0, 1]).range([0, this.chartWidth]);
  y = scaleLinear().domain([0, 1]).range([this.chartHeight, 0]);

  state = {
    ca: {  },
    mg:  {  },
    phos:  {  }
  }

  assignToState = () => {
    let empty = {
      title: '', 
      value: '',
      unit: '',
      time: '',
      interpretation: 'black'
    }
    let ca, mg, phos;
    if (this.props.results.calcium && this.props.results.calcium.length) {
      ca = {
        title: 'Calcium', 
        value: this.props.results.calcium[this.props.results.calcium.length -1].value,
        unit: this.props.results.calcium[this.props.results.calcium.length -1].unit,
        time: moment(this.props.results.calcium[this.props.results.calcium.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.calcium[this.props.results.calcium.length -1].abnormal ? 'red' : 'black'
      }
    }
    else ca = empty;
    if (this.props.results.magnesium && this.props.results.magnesium.length) {
      mg = {
        title: 'Magnesium', 
        value: this.props.results.magnesium[this.props.results.magnesium.length -1].value,
        unit: this.props.results.magnesium[this.props.results.magnesium.length -1].unit,
        time: moment(this.props.results.magnesium[this.props.results.magnesium.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.magnesium[this.props.results.magnesium.length -1].abnormal ? 'red' : 'black'
      }
    }
    else mg = empty;
    if (this.props.results.phosphate && this.props.results.phosphate.length) {
      phos = {
        title: 'Phosphate', 
        value: this.props.results.phosphate[this.props.results.phosphate.length -1].value,
        unit: this.props.results.phosphate[this.props.results.phosphate.length -1].unit,
        time: moment(this.props.results.phosphate[this.props.results.phosphate.length -1].effectiveDateTime).format(config.dateFormat),
        interpretation: this.props.results.phosphate[this.props.results.phosphate.length -1].abnormal ? 'red' : 'black'
      }
    }
    else phos = empty;
    this.setState({
      ca: ca,
      mg: mg,
      phos: phos
    })
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.results !== prevProps.results) {
      this.assignToState();
    }
  }

  render() {
  
    return(
    <div>
      <span className="labTitles" >Ca-Mg-Phos</span>
      <br />
      <br />
      <svg preserveAspectRatio="xMinYMid" x="0" y = "0" viewBox="0 -10 100 100" style={{overflow:"hidden"}}>
      <g stroke="grey">
        <line x1={this.x(0.05)} x2={this.x(0.5)} y1={this.y(1)} y2={this.y(0.55)} />
        <line x1={this.x(0.5)} x2={this.x(0.95)} y1={this.y(0.55)} y2={this.y(1)} />
        <line x1={this.x(0.5)} x2={this.x(0.5)} y1={this.y(0.55)} y2={this.y(0.1)} />
      </g>
      <g fontSize={this.fontSize}>
        <text x={this.x(.25)} y={this.y(.4)} textAnchor="middle" fill={this.state.mg.interpretation} alignmentBaseline="central" data-tip data-for="registerTip" >{this.state.mg.value}
          <title>
                {this.state.mg.title}{'\n'}
                {this.state.mg.unit}{'\n'}
                {this.state.mg.time}
            </title>
        </text>
        <text x={this.x((.75))} y={this.y(.4 )} textAnchor="middle" fill={this.state.phos.interpretation} alignmentBaseline="central" >{this.state.phos.value}
        <title>
                {this.state.phos.title}{'\n'}
                {this.state.phos.unit}{'\n'}
                {this.state.phos.time}
            </title>
        </text>
        <text x={this.x((.5))} y={this.y(.85)} textAnchor="middle" fill={this.state.ca.interpretation} alignmentBaseline="central" >{this.state.ca.value}
        <title>
                {this.state.ca.title}{'\n'}
                {this.state.ca.unit}{'\n'}
                {this.state.ca.time}
            </title>
        </text>
      </g>
      </svg>
    </div>  
    )
  }
}
