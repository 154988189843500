import PropTypes from "prop-types";
import React, { Component, PureComponent } from "react";
import config from '../config';
import moment from 'moment';

class Spot extends PureComponent {

  filter = `url(#${this.props.labType})`;

  render() {
    return (
      <g>
        <circle
          cx={this.props.cx}
          cy={this.props.cy}
          r={1}
          style={{ fill: "red", stroke: "red" }}
        />
        <text
          filter={this.filter}
          id="tooltip"
          x={this.props.cx + this.props.svgWidth / 40}
          y={this.props.cy}
          fontSize="0.3em"
          fill="white"
        >
          {this.props.tooltip}
        </text>
      </g>
    );
  }
}

class Cursor extends PureComponent {
  render() {
    return (
      <line
        x1={this.props.x1}
        x2={this.props.x2}
        y1={0}
        y2={this.props.height}
        style={{ strokeWidth: 1, stroke: "red" }}
      />
    );
  }
}

const offscreen = -1000;

class SparklinesInteractiveLayer extends Component {
  static propTypes = {
    points: PropTypes.arrayOf(PropTypes.object),
    height: PropTypes.number,
    width: PropTypes.number,
    onMouseMove: PropTypes.func,
    onMouseLeave: PropTypes.func
  };

  static defaultProps = {
    onMouseMove: () => {},
    onMouseLeave: () => {}  
  };

  constructor(props) {
    super(props);
    this.state = {
      cx: offscreen,
      cy: offscreen,
      isActive: false,
    };
  }

  onMouseMove = (e) => {
    // console.log("yo4");
    e.stopPropagation();

    const { width, points, originalData } = this.props;
    const datapoints = points;
    const lastItemIndex = datapoints[datapoints.length - 1];

    // console.log(e.nativeEvent.offsetX)
    const mouseX = Math.floor(
      e.nativeEvent.offsetX / (this.rect.getBoundingClientRect().width / width)
    );

    let pointIndex = 0;
    let nextDataPoint = datapoints.find((entry) => {
      const match = entry.x >= mouseX;
      // console.log(match)
      if (match && !pointIndex) {
        return match;
      }
      pointIndex = pointIndex + 1;
      return match;
    });

    if (!nextDataPoint) {
      nextDataPoint = datapoints[lastItemIndex];
    }

    // console.log(nextDataPoint);

    let previousDataPoint = datapoints[datapoints.indexOf(nextDataPoint) - 1];
    let currentDataPoint;
    let halfway;

    if (previousDataPoint) {
      halfway =
        previousDataPoint.x + (nextDataPoint.x - previousDataPoint.x) / 2;
      currentDataPoint = mouseX >= halfway ? nextDataPoint : previousDataPoint;
    } else {
      currentDataPoint = nextDataPoint;
    }

    if (!currentDataPoint) return;

    const x = currentDataPoint.x;
    const y = currentDataPoint.y;

    let unit = originalData.unit ? originalData.unit : ''
    if (x !== this.state.cx) {
      this.setState({
        tooltip: `${originalData.y[pointIndex]} ${unit} ${originalData.dates[pointIndex]}`,
      });
    }

    this.setState({
      cx: x,
      cy: y,
    });

    let tooltip = this.state.tooltip;
    for (let i = 0; i < datapoints.length; i++) {
      if (datapoints[i].x === currentDataPoint.x) {
        if (
          tooltip !==
          `${originalData.y[i]} ${unit} ${moment(originalData.dates[i]).format(config.dateFormat)}`
        ) {
          this.setState({
            tooltip: `${originalData.y[i]} ${unit} ${moment(originalData.dates[i]).format(config.dateFormat)}`,
          });
        }
        break;
      }
    }

    this.props.onMouseMove(
      currentDataPoint,
      Math.max(0, pointIndex - 1),
      e.nativeEvent.offsetX,
      e.nativeEvent.offsetY
    );
  };

  onMouseLeave = () => {
    // console.log('mouse leave')
    if (this.state.isActive) {
      return;
    }

    this.setState({ cx: offscreen, cy: offscreen });
    this.props.onMouseLeave();
  };

  render() {
    const { height, width, style, labType} = this.props;
    const { cx, cy } = this.state;

    return (
        <g>
          <Spot cx={cx} cy={cy} svgWidth={width} tooltip={this.state.tooltip} labType={labType}/>
          <Cursor x1={cx} x2={cx} height={height} />
          <rect
            ref={(_ref) => {
              this.rect = _ref;
            }}
            height={height}
            width={width}
            style={{ fill: "transparent", stroke: "transparent", ...style }}
            onMouseMove={this.onMouseMove}
            onMouseLeave={this.onMouseLeave}
            onClick={this.props.onClick}
          />
        </g>
    );
  }
}

export default SparklinesInteractiveLayer;
