import React, { Component } from "react";
import { CCollapse } from "@coreui/react";

// Formatting
import { Divider } from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {
  faCheckSquare,
  faMinusSquare,
} from "@fortawesome/free-solid-svg-icons";
import RepeatIcon from "@mui/icons-material/Repeat";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import CircularProgress from "@mui/joy/CircularProgress";

//Example Data
import { Summary } from "./samples/Summary";
import { Summary2 } from "./samples/Summary2";
import { Summary3 } from "./samples/Summary3";

// Request & date management
import axios from "axios";

// Data mamagement
import { FHIR_to_model } from "./helpers/FHIR_to_model";
import { emptyDataModel } from "./helpers/emptyDataModel";
import config from "./config";
import moment from "moment";

// Components of IPASS View
import Contact from "./B_Contact";
import Snapshot from "./B_Snapshot";
import Vitals from "./B_Vitals";
import Data from "./B_Data";
import Notes from "./B_Notes";
import Meds from "./B_Meds";
import Links from "./B_Links";

// Git Info
import GitInfo from "react-git-info/macro";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const gitInfo = GitInfo();

export class Ipass extends Component {
  state = emptyDataModel();

  defaultData = (e) => {
    let bundle = Summary();
    if (e.target.id === "Data2") {
      bundle = Summary2();
    } else if (e.target.id === "Data3") {
      bundle = Summary3();
    }
    let sorted = FHIR_to_model(bundle);
    console.log(sorted);
    this.setState({
      data: sorted,
    });
  };

  updateData = function (id) {
    if (!id) {
      id = this.state.value;
    }
    if (!id || id === "0") {
      console.log("skipping load since no id");
      this.setState({
        loadState: false,
      });
    } else {
      console.log(
        `requesting data: ${config.server}/Patient/${id}/$everything?_count=10000`
      );
      axios({
        method: "post",
        url: `${config.server}/Patient/${id}/$everything?_count=10000`,
        headers: {},
      }).then((res) => {
        console.log(res);
        let sorted = FHIR_to_model(res.data);
        console.log(sorted);
        this.setState({
          data: sorted,
          loadState: false,
        });
      });
    }
  };

  clearAll = () => {
    let empty = emptyDataModel();
    empty.loadState = false;
    this.setState(empty);
  };

  flipAction = (e) => {
    let target = e.target.id;
    if (!target) {
      target = e.target.parentNode.id;
    }
    if (this.state.actions[target].iconName === "square-check") {
      let state = {
        actions: this.state.actions,
      };
      state.actions[target] = faMinusSquare;
      this.setState(state);
    } else {
      let state = {
        actions: this.state.actions,
      };
      state.actions[target] = faCheckSquare;
      this.setState(state);
    }
  };

  handleChange = (e) => {
    this.setState({ value: e.target.value });
  };

  handleClick = () => {
    this.updateData();
  };

  iconState = function (visible) {
    if (!visible) return <ArrowRightIcon></ArrowRightIcon>;
    else return <ArrowDropDownIcon></ArrowDropDownIcon>;
  };

  setVisible = (visible) => {
    if (visible) {
      this.setState({
        debugging: false,
      });
    } else {
      this.setState({
        debugging: true,
      });
    }
  };

  lastUpdated = () => {
    if (!this.state.data.latestResult && !this.state.data.latestVital) {
      return "Not available";
    } else {
      if (this.state.data.latestResult > this.state.data.latestVital) {
        return moment(this.state.data.latestResult).format(config.dateFormat);
      } else {
        return moment(this.state.data.latestResult).format(config.dateFormat);
      }
    }
  };

  componentDidMount = () => {
    let parts = window.location.href.split("/");
    let id = parts[parts.length - 1];
    if (this.state.loadState === true) {
      if (id) {
        this.updateData(id);
      } else {
        this.setState({
          loadState: false,
        });
      }
    }
  };

  render() {
    return (
      <div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              Patient Last Updated: {this.lastUpdated()}
            </Grid>
            <Grid item xs={8} style={{ textAlign: "center" }}>
              <h2>
                <RepeatIcon className="bigIcon" />
                &nbsp;&nbsp;Interhospital Transfer App
              </h2>
            </Grid>
            <Grid item xs={2}>
              <a
                href={
                  window.location.href.slice(
                    0,
                    window.location.href.indexOf("/patient")
                  ) + "/finder"
                }
                style={{ float: "right" }}
              >
                Back to Patient Finder
              </a>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Contact
              Patient={this.state.data.patient}
              allergies={this.state.data.allergies}
              encounter={this.state.data.currentEncounter}
            ></Contact>

            <Snapshot
              Patient={this.state.data.patient}
              problems={this.state.data.problems}
              custom={this.state.data.custom}
              vitals={this.state.data.vitals}
              latest={this.state.data.latestVital}
              daysBack={this.state.data.dynamicDaysBackVitals}
            ></Snapshot>

            <Vitals
              Patient={this.state.data.patient}
              problems={this.state.data.problems}
              custom={this.state.data.custom}
              vitals={this.state.data.vitals}
              latest={this.state.data.latestVital}
              daysBack={this.state.data.dynamicDaysBackVitals}
            ></Vitals>

            <Data
              otherData={this.state.data.otherData}
              results={this.state.data.results}
              latest={this.state.data.latestResult}
              gender={this.state.data.patient.gender}
              daysBack={this.state.data.dynamicDaysBackResults}
            ></Data>

            <Notes notes={this.state.data.notes}></Notes>

            <Meds medications={this.state.data.medications}></Meds>

            <Links></Links>
          </Grid>
        </Box>
        <Divider />
        <Button onClick={() => this.setVisible(this.state.debugging)}>
          {this.iconState(this.state.debugging)}
          Debugging Tools
        </Button>
        <CCollapse visible={this.state.debugging}>
          <h2>Manual Data Loading (not for production use)</h2>
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <h3>Examples</h3>
              <div>
                <Button id="Data1" color="success" onClick={this.defaultData}>
                  Example Evan Lewis (NSTEMI)
                </Button>
              </div>
              <div>
                <Button id="Data2" color="success" onClick={this.defaultData}>
                  Example John Smith (Pancreatitis)
                </Button>
              </div>
              <div>
                <Button id="Data3" color="success" onClick={this.defaultData}>
                  Example Madison Turner (ARDS)
                </Button>
              </div>
              <div>
                <Button color="error" onClick={this.clearAll}>
                  Clear All
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              <h3>Live FHIR Request (requires summary operation)</h3>
              <table style={{ width: "70%" }}>
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "80%" }} />
                </colgroup>
                <tbody>
                  <tr>
                    <td>
                      <label>URL of FHIR R4 server</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="full"
                        value={this.state.server}
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label>Patient id on server</label>
                    </td>
                    <td>
                      <input
                        type="text"
                        className="full"
                        value={this.state.value}
                        onChange={this.handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
              <br />
              <Button variant="contained" onClick={this.handleClick}>
                Update data
              </Button>
            </Grid>
          </Grid>
        </CCollapse>
        <Modal
          id="modalLoading"
          open={this.state.loadState}
          onClose={this.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ textAlign: "center" }}
            >
              Searching for data...
              <br />
              <CircularProgress />
              <br />
              This window will automatically close when data received
            </Typography>
          </Box>
        </Modal>
        <div>
          Developed and hosted by More Informatics, Inc in collaboration with
          Dr. Stephanie Mueller and Dr. Lipika Samal of Harvard Medical School &
          MassGeneralBrigham. contact{" "}
          <a href="mailto:johnd@moreinformatics.com">
            johnd@moreinformatics.com
          </a>{" "}
          for questions.
        </div>
        <div>
          &copy; 2024 Mass General Brigham Incorporated. All Rights Reserved.
        </div>
        <div>Version: {gitInfo.commit.shortHash}</div>
        <Divider />
      </div>
    );
  }
}
