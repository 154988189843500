import "./App.css";
import { Ipass } from "./A_Ipass";
import Box from "@mui/material/Box";
import "@coreui/coreui/dist/css/coreui.min.css";
// import { ThemeProvider,  } from "@mui/material/styles";
// import theme from "./theme.js";

function App() {
  return (     
      <Box sx={{ flexGrow: 1 }}>
        <Ipass></Ipass>
        <br />
      </Box>
  );
}

export default App;
